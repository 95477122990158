import { useEffect, useState } from "react";
import { Button, Divider, makeStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { RouteComponentProps } from "react-router-dom";
import WeekReportApi, { WeekReportItem } from "../../data/weekReport";
import UserApi, {
  Gender,
  TravelOption,
  UserItem,
  SimpleUserItem,
} from "../../data/user";
import { WeekReports } from "../../component/WeekReports";
import { Title } from "../../component/Title";

interface RouterMatch {
  userId: string;
  fromYear: string;
  fromWeekNumber: string;
  toYear: string;
  toWeekNumber: string;
}

const useStyles = makeStyles({
  horizontalChildren: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
  },
  approveButton: {
    marginLeft: "auto",
  },
  username: {
    position: "absolute",
    bottom: "10px",
  },
  usernameAndApproveRow: {
    marginTop: "20px",
  },
  usernameReportSeparator: {
    marginBottom: "10px",
  },
  tableSeparator: {
    margin: "5px",
  },
});

const emptyUser: UserItem = {
  userId: "",
  employeeNumber: "",
  firstName: "",
  initials: "",
  middleName: "",
  lastName: "",
  name: "",
  emailAddress: "",
  googleAccountActive: false,
  dateOfBirth: "",
  nationality: "",
  gender: Gender.NotSpecified,
  iban: "",
  employmentStartDate: "",
  employmentEndDate: "",
  travelOption: TravelOption.CompanyLeaseCar,
  mobilityBudgetAmount: "0",
  contractedHours: 0,
  roles: [],
  projects: [],
  homeAddressId: "",
  homeAddress: "",
  userAccountStatus: "",
  errorMessage: "",
};

export const WeekReportDetails = (props: RouteComponentProps<RouterMatch>) => {
  const classes = useStyles();

  let userId = props.match.params.userId;
  const fromYear = props.match.params.fromYear;
  const fromWeekNumber = props.match.params.fromWeekNumber;

  const emptyWeekReportItem: WeekReportItem = {
    approved: false,
    id: "",
    submitted: false,
    sentToAdministration: false,
    userId: "",
    weekNumber: parseInt(fromWeekNumber),
    year: parseInt(fromYear),
    entries: [],
    trips: [],
  };

  const [weekReportItem, setWeekReportItem] = useState<WeekReportItem>();
  const [currentUser, setCurrentUser] = useState<UserItem>(emptyUser);
  const [allUsers, setAllUsers] = useState<SimpleUserItem[]>([]);

  const goToReport = (increment: number): void => {
    window.stop();
    setWeekReportItem(emptyWeekReportItem);
    let userIndex = getUserIndex(currentUser.userId) + increment;
    userId = allUsers[userIndex].userId;
    WeekReportApi.getWeekReports({
      userId: userId,
      fromWeekNumber: parseInt(fromWeekNumber),
      fromYear: parseInt(fromYear),
      toWeekNumber: parseInt(fromWeekNumber),
      toYear: parseInt(fromYear),
    })
      .then((weekReports) => {
        if (weekReports.length > 0) {
          setWeekReportItem(weekReports[0]);
        }
      })
      .finally(() => {
        UserApi.getUser(userId)
          .then((user) => {
            setCurrentUser(user);
          })
          .finally(() => {
            props.history.push(
              `/admin/weekreportdetails/${userId}/${fromYear}/${fromWeekNumber}`
            );
          });
      });
  };

  useEffect(() => {
    WeekReportApi.getWeekReports({
      userId: userId,
      fromYear: parseInt(fromYear),
      fromWeekNumber: parseInt(fromWeekNumber),
      toYear: parseInt(fromYear),
      toWeekNumber: parseInt(fromWeekNumber),
    }).then(
      (weekReports) => {
        if (weekReports.length > 0) {
          setWeekReportItem(weekReports[0]);
        } else {
          // No week report available yet
        }
      },
      (err) => {
        // Request aborted
      }
    );
  }, [fromYear, fromWeekNumber, userId]);

  useEffect(() => {
    setCurrentUser(emptyUser);
    UserApi.getUser(userId).then(
      (user) => {
        setCurrentUser(user);
        UserApi.getUsers().then((users) => {
          setAllUsers(users);
        });
      },
      (err) => {
        // Request aborted
      }
    );
  }, [fromWeekNumber, fromYear, userId]);

  const getUserIndex = (userId: string): number => {
    let userIndex = -1;
    let userInList = allUsers.find((user) => user.userId === userId);
    if (userInList) {
      userIndex = allUsers.indexOf(userInList);
    }
    return userIndex;
  };

  const canNavigate = (direction: number): boolean => {
    if (currentUser) {
      let userIndex = getUserIndex(currentUser.userId);
      if (userIndex === -1) {
        return false;
      } else {
        return (
          userIndex + direction >= 0 && userIndex + direction < allUsers.length
        );
      }
    }
    return false;
  };

  return (
    <div>
      <div className={classes.horizontalChildren}>
        <Title>Submitted week reports</Title>
        <div
          className={`${classes.horizontalChildren} ${classes.approveButton}`}
        >
          <Button disabled={!canNavigate(-1)} onClick={() => goToReport(-1)}>
            <ArrowBackIosIcon />
          </Button>
          <Button disabled={!canNavigate(+1)} onClick={() => goToReport(+1)}>
            <ArrowForwardIosIcon />
          </Button>
        </div>
      </div>
      <Divider variant="fullWidth" />
      <div className={classes.usernameAndApproveRow}>
        <WeekReports
          weekReports={
            weekReportItem ? [weekReportItem] : [emptyWeekReportItem]
          }
          users={[currentUser]}
          presentOnly={false}
        />
      </div>
    </div>
  );
};
