import { Route } from "react-router-dom";

import { AppBarRoute } from "../WithAppBar";
import { Dashboard } from "./admin/Dashboard";
import { CustomerList } from "./admin/CustomerList";
import { CustomerItem } from "./admin/CustomerItem";
import { ProjectList } from "./admin/ProjectList";
import { ProjectItem } from "./admin/ProjectItem";
import { BillingTool } from "./admin/BillingTool";
import { UserList } from "./admin/UserList";
import { UserItem } from "./admin/UserItem";
import { WeekReportHistory } from "./admin/WeekReportHistory";
import { WeekReportDetails } from "./admin/WeekReportDetails";
import { ReviewDeclarations } from "./admin/ReviewDeclarations";
import { Account } from "../data/principal";
import { HolidaysList } from "./admin/HolidayForm";
import { HolidayAllowance } from "./admin/HolidayAllowance/HolidayAllowance";
import { ProfileItem } from "./ProfileItem";
import { Declaration } from "./Declaration";
import { UpdateDeclaration } from "./UpdateDeclaration";

export const AdminRoutes = (account: Account) => {

  if (!account || !account.isAdmin) {
    return null;
  }


  return (
    <div>
      {routes.map((route, index) => (
        <Route key={index} {...route.props} />
      ))}
    </div>
  );
};

export const routes: AppBarRoute[] = [
  {
    title: "Dashboard",
    props: {
      path: "/admin",
      exact: true,
      component: Dashboard,
    },
    isExpenses: true,
  },
  {
    title: "Billing Tool",
    props: {
      path: "/admin/billingtool",
      component: BillingTool,
    },
  },
  {
    title: "Declaration",
    props: {
      path: "/admin/declaration",
      component: Declaration,
    },
  },
  {
    title: "Expenses",
    props: {
      path: "/admin/reviewDeclarations",
      exact: true,
      component: ReviewDeclarations,
    },
    isExpenses: true,
  },
  {
    title: "Customers",
    props: {
      path: "/admin/customers",
      exact: true,
      component: CustomerList,
    },
  },
  {
    props: {
      path: "/admin/customers/:id",
      component: CustomerItem,
    },
  },
  {
    title: "Holidays",
    props: {
      path: "/admin/holidays",
      exact: true,
      component: HolidaysList,
    },
  },
  {
    title: "Holiday Allowances",
    props: {
      path: "/admin/holiday-allowance",
      component: HolidayAllowance,
    },
  },
  {
    title: "Projects",
    props: {
      path: "/admin/projects",
      exact: true,
      component: ProjectList,
    },
  },
  {
    props: {
      path: "/admin/projects/:id/:secondaryId?",
      component: ProjectItem,
    },
  },
  {
    props: {
      path: "/admin/updateDeclaration/:id",
      component: UpdateDeclaration,
    },
  },
  {
    title: "Users",
    props: {
      path: "/admin/users",
      exact: true,
      component: UserList,
    },
  },
  {
    props: {
      path: "/admin/users/:id",
      component: UserItem,
    },
  },
  {
    props: {
      path: "/admin/profile",
      component: ProfileItem,
    },
  },
  {
    props: {
      path: "/admin/weekreporthistory",
      component: WeekReportHistory,
    },
  },
  {
    props: {
      path: "/admin/weekreportdetails/:userId/:fromYear/:fromWeekNumber",
      component: WeekReportDetails,
    },
  },
];
