import React, { useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { AttachFile } from "@material-ui/icons";
import { useContext, useState } from "react";
import { Utils } from "../../data/util";
import UserDocumentApi, { UserDocumentDataItem } from "../../data/userDocument";
import { UserItem, SimpleUserItem } from "../../data/user";
import { SnackbarContext } from "../SnackbarProvider";
import { compareDesc } from "date-fns/esm";

type UserAction = "" | "delete";

export enum TableTypes {
  SUBMITTED = "SUBMITTED",
}

const animationTableFlipTimeInMs = 800;

const animationFadeoutTimeInMs: number = 500;

const useStyles = makeStyles((theme: Theme) => ({
  "@keyframes rotate-div": {
    "0%": {
      transform: "perspective(120px) rotateY(0deg)",
      "-webkit-transform": "perspective(120px) rotateY(0deg)",
    },
    "100%": {
      transform: "perspective(120px) rotateY(-89.9deg)",
      "-webkit-transform": "perspective(120px) rotateY(-89.9deg)",
    },
  },
  "@keyframes rotate-div-back": {
    "0%": {
      transform: "perspective(120px) rotateY(89.9deg)",
      "-webkit-transform": "perspective(120px) rotateY(89.9deg)",
    },
    "100%": {
      transform: "perspective(120px) rotateY(0deg)",
      "-webkit-transform": "perspective(120px) rotateY(0deg)",
    },
  },
  rotatingContainer: {
    WebkitAnimation: `$rotate-div ${animationTableFlipTimeInMs / 1000}s ${
      theme.transitions.easing.easeInOut
    }`,
    animation: `$rotate-div ${animationTableFlipTimeInMs / 1000}s ${
      theme.transitions.easing.easeInOut
    }`,
  },
  rotatingContainerBack: {
    WebkitAnimation: `$rotate-div-back ${animationTableFlipTimeInMs / 1000}s ${
      theme.transitions.easing.easeInOut
    }`,
    animation: `$rotate-div-back ${animationTableFlipTimeInMs / 1000}s ${
      theme.transitions.easing.easeInOut
    }`,
  },

  allProcessedBlock: {
    border: "2px dashed #bbb",
    textAlign: "center",
    minHeight: "100px",
    justifyContent: "center",
    alignItems: "center",
  },

  attachmentButton: {
    WebkitTransform: "rotate(45deg)",
    msTransform: "rotate(45deg)",
    transform: "rotate(45deg)",
    cursor: "pointer",
    opacity: "50%",
    "&:hover": {
      opacity: "100%",
    },
  },
  blackToGreyButton: {
    backgroundColor: "#000000",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#666666",
    },
  },
  bottomPart: {
    borderTop: "none",
  },
  divider: {
    minWidth: "1200px",
  },
  handledRow: {
    opacity: 0,
    transition: "opacity 2.5s",
  },
  horizontalChildren: {
    display: "flex",
    justifyContent: "space-between",
  },
  invisibleRow: {
    maxHeight: "0px",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  spinner: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  tableContainer: {
    maxHeight: "700px",
  },
  topPart: {
    borderBottom: "1px dashed #bbb",
  },
  whiteToBlackButton: {
    marginLeft: "15px",
    "&:hover": {
      backgroundColor: "#000000",
      color: "#FFFFFF",
    },
  },

  animatedItem: {
    animation: `$myEffect ${animationFadeoutTimeInMs}ms ${theme.transitions.easing.easeInOut}`,
  },
  animatedItemExiting: {
    animation: `$myEffectExit ${animationFadeoutTimeInMs}ms ${theme.transitions.easing.easeInOut}`,
    opacity: 0,
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  "@keyframes myEffectExit": {
    "0%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },

  "@keyframes fadeoutElement": {
    "0%": {
      transform: "opacity 1",
      "-webkit-transform": "opacity 1",
    },
    "100%": {
      transform: "opacity 0",
      "-webkit-transform": "opacity 0",
    },
  },

  fadeOut: {
    WebkitAnimation: `$fadeoutElement ${animationFadeoutTimeInMs / 1000}s ${
      theme.transitions.easing.easeInOut
    }`,
    animation: `$fadeoutElement ${animationFadeoutTimeInMs / 1000}s ${
      theme.transitions.easing.easeInOut
    }`,
  },
}));

interface TableProps {
  aUser: UserItem;
  aUsers: SimpleUserItem[];
  aDocuments: UserDocumentDataItem[];
  aTableType: TableTypes;
  aListRef?: React.RefObject<HTMLDivElement>;
  aPreviousScrollDiff: React.MutableRefObject<number>;
}

export const DocumentTable = (props: TableProps) => {
  const classes = useStyles();
  const { showError } = useContext(SnackbarContext);
  const [selectedUserDocuments, setSelectedUserDocuments] = useState<string[]>(
    []
  );
  const sortedDocuments = props.aDocuments.sort(
    (a: UserDocumentDataItem, b: UserDocumentDataItem) => {
      const dateA = Utils.parseDateTime(a.submissionDate, a.submissionTime);
      const dateB = Utils.parseDateTime(b.submissionDate, b.submissionTime);
      if (dateA === null) {
        return -1;
      }
      if (dateB === null) {
        return 1;
      }
      return compareDesc(dateA, dateB);
    }
  );

  const [currentAction, setCurrentAction] = useState<UserAction>("");

  const [willProcessAll, setWillProcessAll] = useState(false);

  const [willProcess, setWillProcess] = useState(false);

  const [users] = useState<SimpleUserItem[]>(props.aUsers);

  const [undeletedUserDocuments, setUndeletedUserDocuments] = useState<
    UserDocumentDataItem[]
  >(props.aTableType === TableTypes.SUBMITTED ? sortedDocuments : []);

  useEffect(() => {
    UserDocumentApi.getUndeletedUserDocuments(props.aUser.userId).then(
      (res) => {
        setUndeletedUserDocuments(res);
      }
    );
  }, [props.aUser.userId, willProcess, willProcessAll]);

  const getBorderClass = (
    document: UserDocumentDataItem,
    partIndex?: number
  ): string => {
    return document.parts.length > 1
      ? partIndex === 0
        ? classes.topPart
        : classes.bottomPart
      : "";
  };

  const getRowClass = (documentId: string): string => {
    if (currentAction === "delete") {
      if (selectedUserDocuments.includes(documentId)) {
        return classes.animatedItemExiting;
      }
    }
    return "";
  };

  const handleAction = (action: UserAction): void => {
    let toRemove: string[] = [];

    if (selectedUserDocuments.length > 0 && action === "delete") {
      setWillProcess(true);
      setCurrentAction(action);
      if (selectedUserDocuments.length === undeletedUserDocuments.length) {
        setWillProcessAll(true);
      }
      selectedUserDocuments.forEach((userDocumentId) => {
        UserDocumentApi.deleteUserDocument(userDocumentId)
          .then((res) => {
            if (res) {
              toRemove.push(userDocumentId);
            }
          })
          .finally(() => {
            setTimeout(() => {
              setUndeletedUserDocuments(
                undeletedUserDocuments.filter(
                  (undeletedUserDocument) =>
                    !toRemove.find((id) => id === undeletedUserDocument.id)
                )
              );
              setSelectedUserDocuments([]);
              setCurrentAction("");
              setWillProcess(false);
              setTimeout(() => {
                setWillProcessAll(false);
              }, animationTableFlipTimeInMs + 100);
            }, animationTableFlipTimeInMs - 100);
          });
      });
    } else {
      showError("No user documents were selected");
    }
  };

  const showTable = (): boolean => undeletedUserDocuments.length > 0;

  const getTableContainerClass = (): string =>
    `${classes.tableContainer} ${
      willProcessAll ? classes.rotatingContainer : ``
    }`;

  return (
    <div>
      {showTable() ? (
        <>
          <Divider />
          <Box>
            <Grid
              container
              alignItems="flex-start"
              justifyContent="flex-start"
              spacing={2}
            >
              <Grid container item sm={12}>
                <Grid container item>
                  <TableContainer
                    className={getTableContainerClass()}
                    onScroll={() => {
                      if (props.aListRef) {
                        if (props.aListRef.current) {
                          props.aPreviousScrollDiff.current =
                            props.aListRef.current.scrollHeight -
                            props.aListRef.current.scrollTop;
                        }
                      }
                    }}
                    ref={props.aListRef}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {props.aTableType === TableTypes.SUBMITTED && (
                            <TableCell className={classes.noWrap}>
                              <Checkbox
                                checked={
                                  selectedUserDocuments.length ===
                                  undeletedUserDocuments.length
                                }
                                disabled={currentAction !== ""}
                                onChange={(e, value) => {
                                  if (value === true) {
                                    setSelectedUserDocuments(
                                      undeletedUserDocuments.reduce(
                                        (list, document) => {
                                          list.push(document.id);
                                          return list;
                                        },
                                        [] as string[]
                                      )
                                    );
                                  } else {
                                    setSelectedUserDocuments([]);
                                  }
                                }}
                              />
                            </TableCell>
                          )}
                          <TableCell className={classes.noWrap}></TableCell>
                          <TableCell className={`${classes.noWrap}`}>
                            Type of document
                          </TableCell>
                          {props.aTableType === TableTypes.SUBMITTED && (
                            <TableCell className={classes.noWrap}>
                              Uploaded by
                            </TableCell>
                          )}

                          {props.aTableType === TableTypes.SUBMITTED && (
                            <TableCell className={classes.noWrap}>
                              Upload date
                            </TableCell>
                          )}

                          <TableCell className={classes.noWrap}>ID</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(props.aTableType === TableTypes.SUBMITTED
                          ? undeletedUserDocuments
                          : undeletedUserDocuments
                        ).map((document) =>
                          document.parts.map((part, partIndex) => (
                            <TableRow
                              key={part.yukiId}
                              className={`
                      ${getRowClass(document.id)}
                      ${getBorderClass(document, partIndex)}
                      `}
                            >
                              {props.aTableType === "SUBMITTED" &&
                                partIndex === 0 && (
                                  <TableCell rowSpan={document.parts.length}>
                                    <Checkbox
                                      checked={
                                        selectedUserDocuments.length > 0 &&
                                        selectedUserDocuments.some(
                                          (value) => value === document.id
                                        )
                                      }
                                      disabled={currentAction !== ""}
                                      onChange={(event, value) => {
                                        value === true
                                          ? setSelectedUserDocuments(
                                              (state) => [...state, document.id]
                                            )
                                          : setSelectedUserDocuments((state) =>
                                              state.filter(
                                                (id) => id !== document.id
                                              )
                                            );
                                      }}
                                    />
                                  </TableCell>
                                )}
                              {partIndex === 0 && (
                                <TableCell rowSpan={document.parts.length}>
                                  <AttachFile
                                    className={classes.attachmentButton}
                                    onClick={() => {
                                      let newWindow = window.open("", "_blank");
                                      UserDocumentApi.getUserDocumentAttachmentDownloadUrl(
                                        document.id
                                      ).then((url) => {
                                        newWindow!.location.href = url;
                                      });
                                    }}
                                  ></AttachFile>
                                </TableCell>
                              )}
                              <TableCell
                                className={`${getBorderClass(
                                  document,
                                  partIndex
                                )} ${classes.noWrap}`}
                              >
                                {part.userDocumentType}
                              </TableCell>
                              {props.aTableType === TableTypes.SUBMITTED && (
                                <TableCell
                                  className={`${getBorderClass(
                                    document,
                                    partIndex
                                  )} ${classes.noWrap}`}
                                >
                                  {users
                                    .filter(
                                      (user) => user.userId === document.userId
                                    )
                                    .map((user) => user.name)}
                                </TableCell>
                              )}
                              {props.aTableType === TableTypes.SUBMITTED && (
                                <TableCell
                                  className={`${getBorderClass(
                                    document,
                                    partIndex
                                  )} ${classes.noWrap}`}
                                >
                                  {Utils.viewDateTime(
                                    Utils.parseDateTime(
                                      document.submissionDate,
                                      document.submissionTime
                                    )
                                  ).substr(0, 10)}
                                </TableCell>
                              )}

                              <TableCell
                                className={`${getBorderClass(
                                  document,
                                  partIndex
                                )} ${classes.noWrap}`}
                              >
                                {part.yukiId}
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {props.aTableType === TableTypes.SUBMITTED && (
                    <div style={{ paddingTop: "25px" }}>
                      <Button
                        className={classes.blackToGreyButton}
                        onClick={() => handleAction("delete")}
                        disabled={currentAction !== ""}
                      >
                        Delete selected
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <Grid container item md={12}>
          <Grid
            container
            item
            className={`${classes.allProcessedBlock} ${
              willProcessAll ? classes.rotatingContainerBack : ``
            }`}
          >
            <Grid container item direction="column">
              <Typography variant="subtitle2">{`No documents available for this user yet...`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
