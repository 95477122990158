import { AddressUpdate } from "./address";
import { getAxios } from "./axios";
import { ProjectUser } from "./project";
import { HolidayAllowancePerYear } from "./holiday-allowance";

export enum Gender {
  Male = "MALE",
  Female = "FEMALE",
  NotSpecified = "NOT_SPECIFIED",
}

export enum Role {
  Admin = "ADMIN",
  Employee = "EMPLOYEE",
}

export enum TravelOption {
  CompanyLeaseCar = "COMPANY_LEASE_CAR",
  MobilityBudget = "MOBILITY_BUDGET",
  None = "NONE",
}

export class UserNameBuilder {
  static getFullName(user: UserSummary | UserItem) {
    return `${user.firstName} ${user.middleName || ""} ${user.lastName}`;
  }
}

export interface SimpleUserItem {
  userId: string;
  homeAddressId: string;
  employeeNumber: string;
  name: string;
  emailAddress: string;
  contractedHours?: number;
  roles?: Role[];
  projects?: ProjectUser[];
  userAccountStatus?: string;
  errorMessage: string;
}

interface UserBase {
  firstName: string;
  initials: string;
  middleName: string;
  lastName: string;
  name: string;
  emailAddress: string;
  googleAccountActive: boolean | undefined;
  dateOfBirth?: string;
  nationality?: string;
  gender?: Gender;
  iban?: string;
  licensePlateNumber?: string;
  phoneNumber?: string;
  privateEmailAddress?: string;
  linkedinUrl?: string;
  hardwarePreferences?: string;
  plannedHolidays?: string;
  travelOption?: TravelOption;
  mobilityBudgetAmount?: string;
  employmentStartDate?: string;
  employmentEndDate?: string;
  contractedHours?: number;
  roles?: Role[];
  projects?: ProjectUser[];
  userAccountStatus: string;
  errorMessage: string;
}

export interface UserSummary {
  userId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  employmentStartDate: string;
  employmentEndDate: string;
}

export interface UserItem extends UserBase {
  userId: string;
  employeeNumber: string;
  homeAddressId: string;
  homeAddress: string;
  holidayAllowances?: HolidayAllowancePerYear | undefined;
}

export interface UserEdit extends UserBase, AddressUpdate {}

export interface CreateUser
  extends Omit<UserEdit, "userAccountStatus" | "errorMessage"> {}

export interface UpdateUser extends UserEdit {
  employeeNumber?: string;
  userId: string;
}

export interface DisableUser {
  userId: string;
}

export const formatRole = (role: Role): string => {
  switch (role) {
    case Role.Admin:
      return "Admin";
    case Role.Employee:
      return "Employee";
    default:
      return "";
  }
};

export default class UserApi {
  static async getUsers(): Promise<SimpleUserItem[]> {
    let response = await getAxios().get<SimpleUserItem[]>(`/simpleuser`);
    return response.data;
  }

  static async getUser(id: string): Promise<UserItem> {
    let response = await getAxios().get<UserItem>(`/user/${id}`);
    return response.data;
  }

  static async createUser(payload: CreateUser): Promise<string> {
    let response = await getAxios().post<string>(`/createUser`, payload);
    return response.data;
  }

  static async updateUser(payload: UpdateUser): Promise<string> {
    let response = await getAxios().post<string>(`/updateUser`, payload);
    return response.data;
  }

  static async disableUser(payload: DisableUser): Promise<void> {
    let response = await getAxios().post<void>(`/disableUser`, payload);
    return response.data;
  }

  static async getUserStatus(): Promise<string> {
    let response = await getAxios().post<string>(`/userstatus`);
    return response.data;
  }

  static async resendOnboardingMail(userId: string): Promise<void> {
    let response = await getAxios().post<void>(
      `/resendOnboardingMail/${userId}`
    );
    return response.data;
  }

  static async reactivateUser(userId: string): Promise<boolean> {
    let response = await getAxios().post<boolean>(`/reactivateUser/${userId}`);
    return response.data;
  }

  static async archiveUser(userId: string): Promise<boolean> {
    let response = await getAxios().post<boolean>(`/archiveUser/${userId}`);
    return response.data;
  }
}
