/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { GridColumns } from "@material-ui/data-grid";
import UserApi, { SimpleUserItem } from "../../data/user";
import {
  Box,
  Button,
  Typography,
  Tooltip,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import GoogleWorkspaceApi from "../../data/googleWorkspace";
import { DataGrid, GridFilterModel } from "@material-ui/data-grid";
import { ErrorPopup } from "../../component/ErrorPopup";
import { CustomPopup } from "../../component/CustomPopup";
import { InfoOutlined } from "@material-ui/icons";
import { SnackbarContext } from "../SnackbarProvider";

interface UserItem {
  id: string;
  name: string;
  roles: string;
  status: string;
  errorMessage: string;
}

interface UserListProps {
  selectUser: (id: string) => void;
}

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
  },
});

const UserListComponent = (props: UserListProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openReactivatePopup, setOpenReactivatePopup] =
    useState<boolean>(false);
  const [errorString, SetErrorString] = useState<string>("");
  const [userId, setUserId] = useState<string>("");

  const [gridColumns, setGridColumns] = useState<GridColumns>([]);
  const [gridRows, setGridRows] = useState<UserItem[]>([]);
  const { showInfo, showError } = useContext(SnackbarContext);

  function UserMapper(users: SimpleUserItem[]): UserItem[] {
    return users.map((user) => {
      const roles = user.roles !== undefined ? user.roles : [];
      const status =
        user.userAccountStatus !== undefined ? user.userAccountStatus : "";

      const newRoles = rolesOrderString(roles);
      const newStatus = changeStatusesNames(status);

      const userItem = {
        id: user.userId,
        name: user.name,
        roles: newRoles,
        status: newStatus,
        errorMessage: user.errorMessage,
      };
      return userItem;
    });
  }

  useEffect(() => {
    UserApi.getUsers()
      .then((res) => {
        setGridColumns(getGridColumns());
        setGridRows(UserMapper(res));
      })
      .catch((err) => {
        console.log(err);
        setGridColumns([]);
        setGridRows([]);
      });
    let stringFilter = localStorage.getItem("filterUserStatus");
    if (stringFilter === null || JSON.parse(stringFilter).items === null) {
      setFilterModel({
        items: [
          { columnField: "status", operatorValue: "is", value: "Active" },
        ],
      });
    } else {
      setFilterModel(JSON.parse(stringFilter));
    }
  }, []);

  function changeStatusesNames(status: string): string {
    let newStatusName;
    switch (status) {
      case "FAILEDTOCREATEACCOUNT":
        newStatusName = "Failed To Create Account";
        break;
      case "PENDINGPROFILECOMPLETION":
        newStatusName = "Pending Profile Completion";
        break;
      case "ACTIVE":
        newStatusName = "Active";
        break;
      case "DEACTIVATED":
        newStatusName = "Deactivated";
        break;
      case "ARCHIVED":
        newStatusName = "Archived";
        break;
      default:
        newStatusName = "";
    }
    return newStatusName;
  }

  function rolesOrderString(roles: string[]): string {
    if (roles.length > 1) {
      return "Admin, Employee";
    } else {
      if (roles[0] === "ADMIN") {
        return "Admin";
      } else {
        return "Employee";
      }
    }
  }

  const resendOnboardingMail = (userId: string) => {
    UserApi.resendOnboardingMail(userId)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const reactivateUser = (userId: string) => {
    UserApi.reactivateUser(userId)
      .then((res) => {
        if (res === true) {
          showInfo("User is successfully reactivated!");
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          showError("Can't reactivate user, please try again!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const retryGwsAccountCreation = (userId: string) => {
    GoogleWorkspaceApi.createGoogleWorkspaceUser(userId)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGridColumns = (): GridColumns => {
    const cols: GridColumns = [];

    cols.push({
      field: "name",
      headerName: "Name",
      flex: 1,
      filterable: true,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              color: "#757ce8",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => props.selectUser(cellValues.row.id)}
          >
            {cellValues.row.name}
          </div>
        );
      },
    });

    cols.push({
      field: "roles",
      headerName: "Roles",
      flex: 1,
      filterable: false,
      sortable: true,
    });

    cols.push({
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      type: "singleSelect",
      valueOptions: [
        "Failed To Create Account",
        "Pending Profile Completion",
        "Active",
        "Deactivated",
        "Archived",
      ],
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.row.status}
            {cellValues.row.status === "Failed To Create Account" ? (
              <IconButton
                onClick={() => {
                  setOpen(true);
                  SetErrorString(cellValues.row.errorMessage);
                }}
              >
                <InfoOutlined />
              </IconButton>
            ) : null}
          </div>
        );
      },
    });

    cols.push({
      field: "Actions",
      flex: 1,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.row.status === "Failed To Create Account" ? (
              <>
                <Tooltip
                  title={
                    <Typography color="inherit">
                      Click this button to retry the account creation.
                    </Typography>
                  }
                  placement="right"
                  arrow
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => retryGwsAccountCreation(cellValues.row.id)}
                  >
                    Retry
                  </Button>
                </Tooltip>
              </>
            ) : null}
            {cellValues.row.status === "Pending Profile Completion" ? (
              <Tooltip
                title={
                  <Typography color="inherit">
                    Click this button to resend the onboarding mail.
                  </Typography>
                }
                placement="right"
                arrow
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => resendOnboardingMail(cellValues.row.id)}
                >
                  Resend
                </Button>
              </Tooltip>
            ) : null}
            {cellValues.row.status === "Deactivated" ? (
              <Tooltip
                title={
                  <Typography color="inherit">
                    Click this button to reactivate this user account.
                  </Typography>
                }
                placement="right"
                arrow
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setOpenReactivatePopup(true);
                    setUserId(cellValues.row.id);
                  }}
                >
                  Reactivate
                </Button>
              </Tooltip>
            ) : null}
          </div>
        );
      },
    });

    return cols;
  };

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [{ columnField: "status", operatorValue: "is", value: "Active" }],
  });

  const filterModelFunc = (model: GridFilterModel) => {
    setFilterModel(model);
    localStorage.setItem("filterUserStatus", JSON.stringify(model));
  };

  const classes = useStyles();

  // ToDO: Lazy loading should be added to ensure that the size of users does not exceed 100
  return (
    <Box sx={{ height: 650, width: "100%" }}>
      <DataGrid
        autoHeight
        rows={gridRows}
        columns={gridColumns}
        hideFooterPagination
        rowsPerPageOptions={[gridRows.length]}
        // pageSize={gridRows.length}
        disableSelectionOnClick
        filterModel={filterModel}
        onFilterModelChange={filterModelFunc}
        className={classes.root}
      />
      <ErrorPopup
        open={open}
        errorData={errorString}
        onClose={() => {
          setOpen(false);
        }}
      />
      <CustomPopup
        open={openReactivatePopup}
        title="Reactivate user?"
        description="Are you sure you want to reactivate this user?"
        onClose={() => {
          setOpenReactivatePopup(false);
        }}
        onDisAgree={() => {
          setOpenReactivatePopup(false);
        }}
        onAgree={() => {
          reactivateUser(userId);
        }}
      />
    </Box>
  );
};

export default UserListComponent;
