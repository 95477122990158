/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Collapse,
  Container,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  makeStyles,
  Theme,
  Typography,
  Box,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";

import { TextInput } from "../../component/TextInput";
import { NumberInput } from "../../component/NumberInput";
import { Dropdown } from "../../component/Dropdown";
import { DropdownWithTooltip } from "../../component/DropdownWithTooltip";
import { DatePicker } from "../../component/DatePicker";
import { Utils } from "../../data/util";
import { CheckBoxes } from "../../component/Checkboxes";
import { nationalities } from "../../data/nationalities";
import { SnackbarContext } from "../../component/SnackbarProvider";
import {
  arrayIsNotEmpty,
  isNotEmpty,
  isNotNegative,
  isQuadEmail,
  isValidDate,
  isValidDateOrEmpty,
  isValidHours,
  isValidIbanOrEmpty,
  isValidPhoneNumberOrEmpty,
  useValidator,
  isValidEmail,
} from "../../hooks/validator";
import UserApi, {
  Gender,
  Role,
  TravelOption,
  UpdateUser,
} from "../../data/user";
import ProjectApi, { ProjectItem, ProjectUser } from "../../data/project";
import { Prediction } from "../../data/googleMaps";
import { GoogleMapsPlacefinder } from "../../component/GoogleMapsPlacefinder";
import { Alert } from "@material-ui/lab";
import { HttpError } from "../../component/HttpError";
import { AmountInput } from "../../component/AmountInput";
import { v4 as uuidv4 } from "uuid";
import {
  DocumentTable,
  TableTypes,
} from "../../component/Documents/DocumentTable";
import { Delete } from "@material-ui/icons";
import UserDocumentApi, {
  CreateUserDocument,
  UserDocumentDataItem,
} from "../../data/userDocument";
import {
  UserItem as aUserItem,
  SimpleUserItem as aSimpleUserItem,
} from "../../data/user";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface RouterMatch {
  id: string;
}

const emptyUser: UpdateUser = {
  userId: "",
  firstName: "",
  initials: "",
  middleName: "",
  lastName: "",
  name: "",
  emailAddress: "",
  googleAccountActive: undefined,
  dateOfBirth: "",
  nationality: "",
  gender: Gender.NotSpecified,
  iban: "",
  licensePlateNumber: "",
  phoneNumber: "",
  privateEmailAddress: "",
  linkedinUrl: "",
  hardwarePreferences: "",
  plannedHolidays: "",
  employmentStartDate: "",
  employmentEndDate: "",
  travelOption: TravelOption.None,
  mobilityBudgetAmount: "0",
  contractedHours: 0,
  roles: [],
  projects: [],
  googleAddressId: "",
  googleAddress: "",
  googleSessionToken: "",
  employeeNumber: "",
  userAccountStatus: "",
  errorMessage: "",
};

const emptyAUser: aUserItem = {
  userId: "",
  firstName: "",
  initials: "",
  middleName: "",
  lastName: "",
  name: "",
  emailAddress: "",
  googleAccountActive: undefined,
  dateOfBirth: "",
  nationality: "",
  gender: Gender.NotSpecified,
  iban: "",
  licensePlateNumber: "",
  phoneNumber: "",
  privateEmailAddress: "",
  linkedinUrl: "",
  hardwarePreferences: "",
  plannedHolidays: "",
  employmentStartDate: "",
  employmentEndDate: "",
  travelOption: TravelOption.None,
  mobilityBudgetAmount: "0",
  contractedHours: 0,
  roles: [],
  projects: [],
  employeeNumber: "",
  homeAddressId: "",
  homeAddress: "",
  userAccountStatus: "",
  errorMessage: "",
};

const roleOptions = {
  ADMIN: "Admin",
  EMPLOYEE: "Employee",
};

const mandatoryCreateUserFields: (keyof UpdateUser)[] = [
  "firstName",
  "initials",
  "lastName",
  "roles",
];

const mandatoryUpdateUserFields: (keyof UpdateUser)[] = [
  "userId",
  "firstName",
  "initials",
  "lastName",
  "emailAddress",
  "roles",
];

const mandatoryCreateAdminFields: (keyof UpdateUser)[] = [
  "privateEmailAddress",
];

const mandatoryUpdateAdminFields: (keyof UpdateUser)[] = [];

const mandatoryCreateEmployeeFields: (keyof UpdateUser)[] = [
  "privateEmailAddress",
  "employmentStartDate",
  "contractedHours",
];
const mandatoryUpdateEmployeeFields: (keyof UpdateUser)[] = [
  "dateOfBirth",
  "nationality",
  "googleAddressId",
  "iban",
  "privateEmailAddress",
  "employmentStartDate",
  "contractedHours",
];

const getValidationMessage = (field: keyof UpdateUser): string => {
  switch (field) {
    case "userId":
      return "UserId is required";
    case "firstName":
      return "First name must not be empty";
    case "initials":
      return "Initials must not be empty";
    case "lastName":
      return "Last name must not be empty";
    case "emailAddress":
      return "Email address must not be empty and must end with @quadsolutions.nl";
    case "dateOfBirth":
      return "Date of birth is required";
    case "nationality":
      return "Nationality is required";
    case "googleAddressId":
      return "Home address is required";
    case "iban":
      return "IBAN must be valid or empty";
    case "phoneNumber":
      return "Phone number must be valid or empty";
    case "privateEmailAddress":
      return "Private email address must not be empty and must be valid";
    case "employmentStartDate":
      return "Employment start date is required";
    case "employmentEndDate":
      return "Employment end date must be valid or empty";
    case "contractedHours":
      return "Contracted hours must be between 0 and 40";
    case "mobilityBudgetAmount":
      return "Mobility budget amount is required";
    case "roles":
      return "A role must be selected";
  }
  return "";
};

const getValidationFunction = (
  field: keyof UpdateUser
): ((value: any) => boolean) => {
  switch (field) {
    case "userId":
      return isNotEmpty;
    case "firstName":
      return isNotEmpty;
    case "initials":
      return isNotEmpty;
    case "lastName":
      return isNotEmpty;
    case "emailAddress":
      return isQuadEmail;
    case "dateOfBirth":
      return isValidDate;
    case "nationality":
      return isNotEmpty;
    case "googleAddressId":
      return isNotEmpty;
    case "iban":
      return isValidIbanOrEmpty;
    case "phoneNumber":
      return isValidPhoneNumberOrEmpty;
    case "privateEmailAddress":
      return isValidEmail;
    case "employmentStartDate":
      return isValidDate;
    case "employmentEndDate":
      return isValidDateOrEmpty;
    case "contractedHours":
      return isValidHours;
    case "mobilityBudgetAmount":
      return isNotNegative;
    case "roles":
      return arrayIsNotEmpty;
  }
  return () => false;
};

const getRequiredFieldsForSettings = (
  newUser: boolean,
  roles: Role[],
  travelOption?: TravelOption
): (keyof UpdateUser)[] => {
  let requiredFields: (keyof UpdateUser)[];
  if (newUser) {
    requiredFields = mandatoryCreateUserFields;
  } else {
    requiredFields = mandatoryUpdateUserFields;
  }

  if (roles.includes(Role.Admin)) {
    if (newUser) {
      requiredFields.concat(mandatoryCreateAdminFields);
    } else {
      requiredFields.concat(mandatoryUpdateAdminFields);
    }
  }
  if (roles.includes(Role.Employee)) {
    if (newUser) {
      requiredFields
        .concat(mandatoryCreateEmployeeFields)
        .concat(
          travelOption === TravelOption.MobilityBudget
            ? "mobilityBudgetAmount"
            : []
        );
    } else {
      requiredFields
        .concat(mandatoryUpdateEmployeeFields)
        .concat(
          travelOption === TravelOption.MobilityBudget
            ? "mobilityBudgetAmount"
            : []
        );
    }
  }
  return requiredFields;
};

const genderValues = {
  MALE: "Male",
  FEMALE: "Female",
  NOT_SPECIFIED: "Not specified",
};

const travelOptionValues = {
  COMPANY_LEASE_CAR: "Company lease car",
  MOBILITY_BUDGET: "Mobility budget",
  NONE: "None",
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: any): {} => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    amountInput: {
      width: "40%",
      marginRight: "5px",
    },
    submitButton: {
      backgroundColor: "#000000",
      color: "#FFFFFF",
      marginTop: "20px",
      "&:disabled": {
        backgroundColor: "#FFFFFF",
      },
    },
    documentPart: {
      marginTop: "30px",
    },
    horizontalChildren: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
    indicator: {
      backgroundColor: "#0081d7",
    },
    inactiveTab: {
      "&:checked": { backgroundColor: "green" },
      "&:hover": {
        backgroundColor: "#cccccc",
        color: "black !important",
      },
    },
    inputFieldRow: {
      marginTop: "30px",
      marginBottom: "10px",
    },
    moveUpToOverlap: {
      position: "absolute",
      bottom: 15,
      right: 15,
      marginTop: "-35px",
    },
    pdfUnsupportedMessage: {
      height: "80px",
    },
    previewUploadOnDesktop: {
      minWidth: window.innerWidth * 0.475,
      maxWidth: window.innerWidth * 0.475,
      paddingBottom: "10px",
    },
    previewUploadOnMobile: {
      minWidth: window.innerWidth * 0.9,
      maxWidth: window.innerWidth * 0.9,
      paddingBottom: "10px",
    },
    spinner: {
      marginLeft: "50%",
    },
    spinnerContainer: {
      position: "relative",
    },
    tab: {
      color: "#0081d7",
    },
    trashButton: {
      float: "right",
      cursor: "pointer",
    },
    uploadBlock: {
      border: "2px dashed #bbb",
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      height: "45px",
      marginTop: "20px",
      paddingTop: "10px",
      paddingBottom: "55px",
      textAlign: "center",
      "&:hover": { cursor: "pointer" },
    },
    whiteToBlackButton: {
      float: "right",
      marginTop: "10px",
      "&:hover": {
        backgroundColor: "#000000",
        color: "#FFFFFF",
      },
    },
    disableButton: {
      marginLeft: "2em",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    classMuiGrid: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  })
);

const maxWidthForMobileInPixels = 1224;

export const UserItem = (props: RouteComponentProps<RouterMatch>) => {
  const listRef = useRef<HTMLDivElement>(null);
  const previousScrollDiff = useRef(0);
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop =
        listRef.current.scrollHeight - previousScrollDiff.current;
    }
  },[]);

  const userId = props.match.params.id;
  const newUser = userId === "new";

  const [projectOptions, setProjectOptions] = useState<{
    [key: string]: string;
  }>({});
  const [user, setUser] = useState<UpdateUser>(emptyUser);
  const { showInfo, showError } = useContext(SnackbarContext);
  const [validationErrorVisible, setValidationErrorVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [showHttpError, setShowHttpError] = useState(false);
  const [retryAction, setRetryAction] = useState<() => void>();
  const classes = useStyles();
  const validator = useValidator<UpdateUser>(
    user,
    getRequiredFieldsForSettings(newUser, [])
  );
  const [currentFile, setCurrentFile] = useState<File | undefined>(undefined);
  const [imageAsURL, setImageAsURL] = useState<string>("");
  const [loadingDocumentMenu, setLoadingDocumentMenu] = useState(true);

  const [documentTypes, setDocumentTypes] = useState<{
    [key: string]: string;
  }>({});
  const [userDocument, setUserDocument] = useState<CreateUserDocument>({
    parts: [{ personId: "", userDocumentType: "" }],
    base64EncodedFile: "",
  });
  const [popupArchiveUser, setPopupArchiveUser] = useState<boolean>(false);
  const [users, setUsers] = useState<aSimpleUserItem[]>([]);
  const [aUser, setAUser] = useState<aUserItem>(emptyAUser);
  const [undeletedUserDocuments, setUndeletedUserDocuments] = useState<
    UserDocumentDataItem[]
  >([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [tabNr, setTabNr] = useState(0);
  const [tableType, setTableType] = useState<TableTypes>(TableTypes.SUBMITTED);
  const [upload, setUpload] = useState<boolean>(false);
  const getClassForTab = (index: number): string =>
    index === tabNr ? classes.tab : classes.inactiveTab;

  useEffect(() => {
    setLoadingDocumentMenu(true);
    UserDocumentApi.getUndeletedUserDocuments(userId).then((res) => {
      setUndeletedUserDocuments(res);
      setTableType(TableTypes.SUBMITTED);
      setLoadingDocumentMenu(false);
    });
    UserApi.getUsers().then((users) => {
      setUsers(users);
    });
    UserApi.getUser(userId).then((user) => {
      setAUser(user);
    });
  }, [userId, upload]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabNr(newValue);
    if (newValue === 1) {
      setLoadingDocumentMenu(true);
      UserDocumentApi.getUndeletedUserDocuments(userId).then((res) => {
        setUndeletedUserDocuments(res);
        setTableType(TableTypes.SUBMITTED);
        setLoadingDocumentMenu(false);
      });
    }
  };

  let isMobileDevice: boolean = useMediaQuery(
    `(max-width:${maxWidthForMobileInPixels}px)`
  );

  const uploadID = "UPLOAD";

  const uploadDocument = (): void => {
    setSubmitting(true);
    let action = () => {
      setShowHttpError(false);
      setSubmitting(true);
      UserDocumentApi.createUserDocument(userDocument).then(
        () => {
          setUpload(true);
          showInfo("Document was uploaded");
          setCurrentFile(undefined);
          setImageAsURL("");
          setUserDocument({
            parts: [
              {
                personId: "",
                userDocumentType: "",
              },
            ],
            base64EncodedFile: "",
          });
          setSubmitting(false);
        },
        (err) => {
          if (err.message !== "Network Error") {
            showError("Failed to upload document");
          } else {
            setShowHttpError(true);
          }
          setSubmitting(false);
        }
      );
      setUpload(false);
    };
    setRetryAction(() => action);
    action();
  };
  const onChangeUserAddress = (value: Prediction | null) => {
    if (value != null) {
      setUser({
        ...user,
        googleAddressId: value.placeId,
        googleAddress: value.description,
      });
      validator.validate(
        "googleAddressId",
        getValidationMessage("googleAddressId"),
        getValidationFunction("googleAddressId"),
        value.placeId
      );
    }
  };
  const onChangeProjects = async (projects: any) => {
    const UserProjects: ProjectUser[] = [];
    var resFilter: ProjectItem[] = [];
    await ProjectApi.getProjects().then((res) => {
      resFilter = res.filter((m) => projects.includes(m.projectId));
    });
    resFilter.forEach((project: ProjectItem) => {
      UserProjects.push({
        projectId: project.projectId,
        userId: user.userId,
        projectName: project.name,
        userName:
          user.firstName + " " + user.middleName
            ? user.middleName + " "
            : "" + user.lastName,
      });
    });
    setUser({ ...user, projects: UserProjects });
  };

  const saveUser = () => {
    setDisabled(true);
    validator.validateMultiple(
      getRequiredFieldsForSettings(
        newUser,
        user.roles ?? [],
        user.travelOption
      ).map((field) => {
        return {
          field,
          message: getValidationMessage(field),
          valid: getValidationFunction(field),
        };
      })
    );

    if (!validator.isValid) {
      setValidationErrorVisible(true);
      window.scrollTo(0, 0);
      setDisabled(false);
      return;
    }

    setValidationErrorVisible(false);

    let action = () => {
      setDisabled(true);
      setShowHttpError(false);

      let request = newUser
        ? UserApi.createUser({
            firstName: user.firstName,
            initials: user.initials,
            middleName: user.middleName,
            lastName: user.lastName,
            name: `${user.firstName} ${user.middleName || ""} ${user.lastName}`,
            emailAddress: user.emailAddress,
            googleAccountActive: user.googleAccountActive,
            dateOfBirth: user.dateOfBirth,
            nationality: user.nationality,
            gender: user.gender ?? Gender.NotSpecified,
            googleAddressId: user.googleAddressId,
            googleAddress: user.googleAddress,
            googleSessionToken: user.googleSessionToken,
            iban: user.iban,
            licensePlateNumber: user.licensePlateNumber,
            phoneNumber: user.phoneNumber,
            privateEmailAddress: user.privateEmailAddress,
            linkedinUrl: user.linkedinUrl,
            hardwarePreferences: user.hardwarePreferences,
            plannedHolidays: user.plannedHolidays,
            employmentStartDate: user.employmentStartDate,
            employmentEndDate: user.employmentEndDate,
            travelOption: user.travelOption ?? TravelOption.None,
            mobilityBudgetAmount: user.mobilityBudgetAmount,
            contractedHours: user.contractedHours ?? 0,
            roles: user.roles,
            projects: user.projects,
          })
        : UserApi.updateUser({
            userId: user.userId,
            firstName: user.firstName,
            initials: user.initials,
            middleName: user.middleName,
            lastName: user.lastName,
            name: `${user.firstName} ${user.middleName || ""} ${user.lastName}`,
            emailAddress: user.emailAddress,
            googleAccountActive: user.googleAccountActive,
            dateOfBirth: user.dateOfBirth,
            nationality: user.nationality,
            gender: user.gender ?? Gender.NotSpecified,
            googleAddressId: user.googleAddressId,
            googleAddress: user.googleAddress,
            googleSessionToken: user.googleSessionToken,
            iban: user.iban,
            licensePlateNumber: user.licensePlateNumber,
            phoneNumber: user.phoneNumber,
            privateEmailAddress: user.privateEmailAddress,
            linkedinUrl: user.linkedinUrl,
            hardwarePreferences: user.hardwarePreferences,
            plannedHolidays: user.plannedHolidays,
            employmentStartDate: user.employmentStartDate,
            employmentEndDate: user.employmentEndDate,
            travelOption: user.travelOption ?? TravelOption.None,
            mobilityBudgetAmount: user.mobilityBudgetAmount,
            contractedHours: user.contractedHours ?? 0,
            roles: user.roles,
            projects: user.projects,
            userAccountStatus: user.userAccountStatus,
            errorMessage: user.errorMessage,
          });

      request.then(
        () => {
          showInfo(`${newUser ? `Created` : `Updated`} user`);
          console.log(`${newUser ? `Created` : `Updated`} user`);
          props.history.push("/admin/users");
        },
        (err) => {
          if (err.message !== "Network Error") {
            showError(
              `Failed to ${newUser ? `create` : `updated`} user: \n${
                err.response.data
              }`
            );
          } else {
            setShowHttpError(true);
          }
          setDisabled(false);
        }
      );
    };
    setRetryAction(() => action);
    action();
  };

  const archiveUser = (userId: string) => {
    setDisabled(true);
    let request = UserApi.archiveUser(userId);
    request.then(
      (res) => {
        setDisabled(false);
        if (res === true) {
          showInfo("User archived in the ERP application");
          UserApi.updateUser({
            ...user,
            googleAccountActive: false,
          });
          setTimeout(() => window.location.reload(), 2000);
        } else {
          showError("Failed to archive user");
        }
      },
      (err) => {
        if (err.message !== "Network Error") {
          showError("Failed to archive user");
        } else {
          setShowHttpError(true);
        }
        setDisabled(false);
      }
    );
  };

  useEffect(() => {
    if (loading) {
      ProjectApi.getProjects().then((projects) => {
        const map: any = {};
        projects.forEach((p: ProjectItem) => {
          map[p.projectId] = p.customerName + " - " + p.name;
        });
        setProjectOptions(map);
      });
      if (newUser) {
        setLoading(false);
        setUser({
          ...user,
          googleSessionToken: uuidv4(),
        });
      } else {
        UserApi.getUser(userId).then(
          (res) => {
            setUser({
              userId: res.userId,
              firstName: res.firstName,
              initials: res.initials,
              middleName: res.middleName,
              lastName: res.lastName,
              name: `${res.firstName} ${res.middleName || ""} ${res.lastName}`,
              emailAddress: res.emailAddress,
              googleAccountActive: res.googleAccountActive,
              googleAddressId: res.homeAddressId,
              googleAddress: res.homeAddress,
              googleSessionToken: uuidv4(),
              contractedHours: res.contractedHours,
              dateOfBirth: res.dateOfBirth,
              employmentEndDate: res.employmentEndDate,
              employmentStartDate: res.employmentStartDate,
              gender: res.gender,
              iban: res.iban,
              licensePlateNumber: res.licensePlateNumber,
              phoneNumber: res.phoneNumber,
              privateEmailAddress: res.privateEmailAddress,
              linkedinUrl: res.linkedinUrl,
              hardwarePreferences: res.hardwarePreferences,
              plannedHolidays: res.plannedHolidays,
              mobilityBudgetAmount: res.mobilityBudgetAmount,
              nationality: res.nationality,
              projects: res.projects,
              roles: res.roles,
              travelOption: res.travelOption,
              employeeNumber: res.employeeNumber,
              userAccountStatus: res.userAccountStatus,
              errorMessage: res.errorMessage,
            });
            validator.updateMandatoryInputs(
              getRequiredFieldsForSettings(
                newUser,
                user.roles ?? [],
                user.travelOption
              ),
              true
            );
            setLoading(false);
          },
          (err) => {
            showError("Failed to load user");
            setLoading(false);
          }
        );
      }
    }
  }, []);

  useEffect(() => {
    if (Object.entries(documentTypes).length === 0) {
      UserDocumentApi.getUserDocumentTypes().then((res) => {
        setDocumentTypes(res);
        setUserDocument({
          ...userDocument,
          parts: [
            {
              personId: "",
              userDocumentType: "",
            },
          ],
        });
      });
    }
  }, []);

  return (
    <div>
      <HttpError
        open={showHttpError}
        close={() => setShowHttpError(false)}
        retry={() => {
          retryAction && retryAction();
        }}
      />
      <Tabs
        value={tabNr}
        onChange={handleChange}
        aria-label="simple tabs example"
        classes={{ indicator: classes.indicator }}
      >
        <Tab
          label={newUser ? "New user" : "User Details"}
          {...a11yProps(0)}
          className={getClassForTab(0)}
        />

        <Tab
          label="DOCUMENTS"
          {...a11yProps(1)}
          className={getClassForTab(1)}
        />
      </Tabs>
      <TabPanel value={tabNr} index={0}>
        <Grid container>
          <Grid container item xs={9} className={classes.classMuiGrid}>
            <Container>
              <HttpError
                open={showHttpError}
                close={() => setShowHttpError(false)}
                retry={() => {
                  retryAction && retryAction();
                }}
              />

              <Dialog
                open={popupArchiveUser}
                onClose={() => {
                  setPopupArchiveUser(false);
                }}
              >
                <DialogTitle> Archive user? </DialogTitle>
                <DialogContent>
                  Warning: Archiving this user will permanently remove their
                  Google account and all associated data. This action cannot be
                  undone. Are you sure you want to continue?
                </DialogContent>
                <DialogActions>
                  <Button
                    autoFocus
                    color="primary"
                    onClick={() => {
                      archiveUser(user.userId);
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    autoFocus
                    color="primary"
                    onClick={() => {
                      setPopupArchiveUser(false);
                      setTimeout(() => setDisabled(false), 500);
                    }}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>

              {validationErrorVisible && (
                <Alert
                  severity="error"
                  onClose={() => setValidationErrorVisible(false)}
                >
                  Please correct the errors in your form and submit again.
                </Alert>
              )}
              <List>
                <ListItem>
                  <TextInput
                    id="user-first-name"
                    label="First name"
                    value={user.firstName}
                    onChange={(firstName) => setUser({ ...user, firstName })}
                    error={validator.get("firstName")}
                    onBlur={() =>
                      validator.validate(
                        "firstName",
                        getValidationMessage("firstName"),
                        getValidationFunction("firstName")
                      )
                    }
                  />
                </ListItem>
                <ListItem>
                  <TextInput
                    id="user-initials"
                    label="Initials"
                    value={user.initials}
                    onChange={(initials) => setUser({ ...user, initials })}
                    error={validator.get("initials")}
                    onBlur={() =>
                      validator.validate(
                        "initials",
                        getValidationMessage("initials"),
                        getValidationFunction("initials")
                      )
                    }
                  />
                </ListItem>
                <ListItem>
                  <TextInput
                    id="user-middle-name"
                    label="Middle name (optional)"
                    value={user.middleName ?? ""}
                    onChange={(middleName) => setUser({ ...user, middleName })}
                  />
                </ListItem>
                <ListItem>
                  <TextInput
                    id="user-last-name"
                    label="Last name"
                    value={user.lastName}
                    onChange={(lastName) => setUser({ ...user, lastName })}
                    error={validator.get("lastName")}
                    onBlur={() =>
                      validator.validate(
                        "lastName",
                        getValidationMessage("lastName"),
                        getValidationFunction("lastName")
                      )
                    }
                  />
                </ListItem>
                {!newUser && (
                  <ListItem>
                    <TextInput
                      id="user-email-address"
                      label="first.lastname@quadsolutions.nl"
                      value={user.emailAddress}
                      onChange={
                        newUser
                          ? (emailAddress) => setUser({ ...user, emailAddress })
                          : undefined
                      }
                      error={validator.get("emailAddress")}
                      onBlur={() =>
                        validator.validate(
                          "emailAddress",
                          getValidationMessage("emailAddress"),
                          getValidationFunction("emailAddress")
                        )
                      }
                    />
                  </ListItem>
                )}
                <ListItem>
                  <CheckBoxes
                    id="user-roles"
                    label="Role(s)"
                    values={user.roles}
                    options={roleOptions}
                    onChange={(roles: any) => {
                      setUser({ ...user, roles });
                      validator.updateMandatoryInputs(
                        getRequiredFieldsForSettings(newUser, roles)
                      );
                      validator.validate(
                        "roles",
                        getValidationMessage("roles"),
                        getValidationFunction("roles"),
                        roles
                      );
                    }}
                    error={validator.get("roles")}
                  />
                </ListItem>
                <Collapse
                  in={
                    newUser &&
                    user.roles?.includes(Role.Admin) &&
                    !user.roles?.includes(Role.Employee)
                  }
                >
                  <ListItem>
                    <TextInput
                      id="private-email-address"
                      label="Private email address"
                      value={user.privateEmailAddress ?? ""}
                      onChange={(privateEmailAddress) =>
                        setUser({ ...user, privateEmailAddress })
                      }
                      error={validator.get("privateEmailAddress")}
                      onBlur={(event) =>
                        validator.validate(
                          "privateEmailAddress",
                          getValidationMessage("privateEmailAddress"),
                          getValidationFunction("privateEmailAddress")
                        )
                      }
                    />
                  </ListItem>
                </Collapse>

                <ListItem>
                  <CheckBoxes
                    id="working-on-projects"
                    label="Working on project(s)"
                    values={user.projects?.map((m) => m.projectId) ?? []}
                    options={projectOptions}
                    onChange={onChangeProjects}
                    error={validator.get("projects")}
                  />
                </ListItem>
                {!newUser && (
                  <>
                    <ListItem id="user-date-of-birth">
                      <DatePicker
                        label="Date of birth"
                        value={
                          user.dateOfBirth
                            ? Utils.parseDate(user.dateOfBirth)
                            : null
                        }
                        onChange={(date) => {
                          if (date instanceof Date && !isNaN(date?.getTime())) {
                            let dateOfBirth: string = Utils.formatDate(date);
                            setUser({ ...user, dateOfBirth });
                            validator.validate(
                              "dateOfBirth",
                              getValidationMessage("dateOfBirth"),
                              getValidationFunction("dateOfBirth"),
                              dateOfBirth
                            );
                          }
                        }}
                        error={validator.get("dateOfBirth")}
                        onBlur={(event) =>
                          validator.validate(
                            "dateOfBirth",
                            getValidationMessage("dateOfBirth"),
                            getValidationFunction("dateOfBirth")
                          )
                        }
                      />
                    </ListItem>
                    <ListItem id="user-nationality">
                      <Dropdown
                        label="Nationality"
                        value={user.nationality ?? ""}
                        values={nationalities}
                        onChange={(nationality) => {
                          setUser({ ...user, nationality });
                          validator.validate(
                            "nationality",
                            getValidationMessage("nationality"),
                            getValidationFunction("nationality"),
                            nationality
                          );
                        }}
                        error={validator.get("nationality")}
                        onClose={() =>
                          validator.validate(
                            "nationality",
                            getValidationMessage("nationality"),
                            getValidationFunction("nationality"),
                            user.nationality
                          )
                        }
                      />
                    </ListItem>
                    <ListItem id="user-gender">
                      <Dropdown
                        label="Gender"
                        value={user.gender || Gender.NotSpecified}
                        values={genderValues}
                        onChange={(gender) => {
                          setUser({ ...user, gender: gender as Gender });
                        }}
                      />
                    </ListItem>
                    <ListItem id="user-address">
                      <GoogleMapsPlacefinder
                        label="Address"
                        value={
                          user.googleAddressId !== undefined &&
                          user.googleAddress !== undefined
                            ? {
                                placeId: user.googleAddressId,
                                description: user.googleAddress,
                              }
                            : undefined
                        }
                        sessionToken={user.googleSessionToken}
                        onSelectPrediction={onChangeUserAddress}
                        error={validator.get("googleAddressId")}
                        onBlur={() =>
                          validator.validate(
                            "googleAddressId",
                            getValidationMessage("googleAddressId"),
                            getValidationFunction("googleAddressId")
                          )
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <TextInput
                        id="user-iban"
                        label="IBAN"
                        value={user.iban ?? ""}
                        onChange={(iban) => setUser({ ...user, iban })}
                        error={validator.get("iban")}
                        onBlur={(event) =>
                          validator.validate(
                            "iban",
                            getValidationMessage("iban"),
                            getValidationFunction("iban")
                          )
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <TextInput
                        id="user-license-plate-number"
                        label="License plate number (optional)"
                        value={user.licensePlateNumber ?? ""}
                        onChange={(licensePlateNumber) =>
                          setUser({ ...user, licensePlateNumber })
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <TextInput
                        id="user-phone-number"
                        label="Phone number (optional)"
                        value={user.phoneNumber ?? ""}
                        onChange={(phoneNumber) =>
                          setUser({ ...user, phoneNumber })
                        }
                        error={validator.get("phoneNumber")}
                        onBlur={(event) =>
                          validator.validate(
                            "phoneNumber",
                            getValidationMessage("phoneNumber"),
                            getValidationFunction("phoneNumber")
                          )
                        }
                      />
                    </ListItem>
                  </>
                )}
                <ListItem>
                  <TextInput
                    id="private-email-address"
                    label="Private email address"
                    value={user.privateEmailAddress ?? ""}
                    onChange={(privateEmailAddress) =>
                      setUser({ ...user, privateEmailAddress })
                    }
                    error={validator.get("privateEmailAddress")}
                    onBlur={(event) =>
                      validator.validate(
                        "privateEmailAddress",
                        getValidationMessage("privateEmailAddress"),
                        getValidationFunction("privateEmailAddress")
                      )
                    }
                  />
                </ListItem>
                {!newUser && (
                  <>
                    <ListItem>
                      <TextInput
                        id="linkedin-url"
                        label="LinkedIn Url (optional)"
                        value={user.linkedinUrl ?? ""}
                        onChange={(linkedinUrl) =>
                          setUser({ ...user, linkedinUrl })
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <TextField
                        id="hardware-preferences"
                        label="Hardware Preferences (optional)"
                        value={user.hardwarePreferences ?? ""}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          setUser({
                            ...user,
                            hardwarePreferences: event.target.value,
                          })
                        }
                        multiline
                        rows={4}
                        placeholder="If you have no preference we'll get you a 32GB DELL/HP equivalent laptop plus standard keyboard, mouse, camera and headset. Also specify a preferred date to pick up the hardware at our office."
                        fullWidth={true}
                        margin="dense"
                        variant="outlined"
                      />
                    </ListItem>
                    <ListItem>
                      <TextField
                        id="planned-holidays"
                        label="Planned Holidays (optional)"
                        value={user.plannedHolidays ?? ""}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          setUser({
                            ...user,
                            plannedHolidays: event.target.value,
                          })
                        }
                        multiline
                        rows={4}
                        placeholder="Specify the dates of the holiday(s) you already planned."
                        fullWidth={true}
                        margin="dense"
                        variant="outlined"
                      />
                    </ListItem>
                  </>
                )}
                <ListItem id="user-start-date">
                  <DatePicker
                    label="Employment start date"
                    value={
                      user.employmentStartDate
                        ? Utils.parseDate(user.employmentStartDate)
                        : null
                    }
                    error={validator.get("employmentStartDate")}
                    onChange={(date) => {
                      if (date instanceof Date && !isNaN(date?.getTime())) {
                        let employmentStartDate: string =
                          Utils.formatDate(date);
                        setUser({ ...user, employmentStartDate });
                        validator.validate(
                          "employmentStartDate",
                          getValidationMessage("employmentStartDate"),
                          getValidationFunction("employmentStartDate"),
                          employmentStartDate
                        );
                      }
                    }}
                    onBlur={(event) =>
                      validator.validate(
                        "employmentStartDate",
                        getValidationMessage("employmentStartDate"),
                        getValidationFunction("employmentStartDate")
                      )
                    }
                  />
                </ListItem>
                <ListItem id="user-end-date">
                  <DatePicker
                    label="Employment end date (optional)"
                    value={
                      user.employmentEndDate
                        ? Utils.parseDate(user.employmentEndDate)
                        : null
                    }
                    error={validator.get("employmentEndDate")}
                    onChange={(date) => {
                      if (date instanceof Date && !isNaN(date?.getTime())) {
                        let employmentEndDate: string = Utils.formatDate(date);
                        setUser({ ...user, employmentEndDate });
                        validator.validate(
                          "employmentEndDate",
                          getValidationMessage("employmentEndDate"),
                          getValidationFunction("employmentEndDate"),
                          employmentEndDate
                        );
                      } else {
                        let employmentEndDate: string = "";
                        setUser({ ...user, employmentEndDate });
                        validator.validate(
                          "employmentEndDate",
                          getValidationMessage("employmentEndDate"),
                          getValidationFunction("employmentEndDate"),
                          employmentEndDate
                        );
                      }
                    }}
                  />
                </ListItem>
                <ListItem id="user-travel-option">
                  <Dropdown
                    label="Travel option"
                    value={user.travelOption ?? TravelOption.None}
                    values={travelOptionValues}
                    onChange={(travelOption) => {
                      validator.updateMandatoryInputs(
                        getRequiredFieldsForSettings(
                          newUser,
                          user.roles ?? [],
                          travelOption as TravelOption
                        )
                      );
                      setUser({
                        ...user,
                        travelOption: travelOption as TravelOption,
                        mobilityBudgetAmount:
                          travelOption === TravelOption.MobilityBudget
                            ? user.mobilityBudgetAmount
                            : undefined,
                      });
                    }}
                  />
                </ListItem>
                {user.travelOption === TravelOption.MobilityBudget && (
                  <ListItem>
                    <AmountInput
                      id="mobility-budget-amount"
                      label="Mobility budget amount"
                      value={
                        user.mobilityBudgetAmount
                          ?.toString()
                          .split(".")
                          .join(",") ?? ""
                      }
                      onChange={(mobilityBudgetAmount) =>
                        setUser({ ...user, mobilityBudgetAmount })
                      }
                      error={validator.get("mobilityBudgetAmount")}
                      onBlur={() =>
                        validator.validate(
                          "mobilityBudgetAmount",
                          getValidationMessage("mobilityBudgetAmount"),
                          getValidationFunction("mobilityBudgetAmount"),
                          user.mobilityBudgetAmount
                        )
                      }
                      prefix="€"
                    />
                  </ListItem>
                )}
                <ListItem id="user-contracted-hours">
                  <NumberInput
                    label="Contracted hours"
                    value={user.contractedHours || 0}
                    onChange={(contractedHours) => {
                      // Check whether updated field is empty before updating user
                      if (!contractedHours) {
                        contractedHours = 0;
                      }
                      setUser({ ...user, contractedHours });
                    }}
                    error={validator.get("contractedHours")}
                    onBlur={() =>
                      validator.validate(
                        "contractedHours",
                        getValidationMessage("contractedHours"),
                        getValidationFunction("contractedHours")
                      )
                    }
                  />
                </ListItem>
                {user.userId && (
                  <ListItem>
                    <TextInput
                      id="employee-number"
                      label="Employee number"
                      value={user.employeeNumber ?? ""}
                    />
                  </ListItem>
                )}
                <ListItem>
                  <Button
                    id="user-save-button"
                    variant="contained"
                    color="primary"
                    onClick={saveUser}
                    disabled={disabled}
                  >
                    Save
                  </Button>
                  {/* {!newUser && (
                <Button
                  onClick={disableUser}
                  disabled={disabled}
                  className={classes.disableButton}
                >
                  Disable
                </Button>
              )} */}
                  {user.userAccountStatus === "DEACTIVATED" && (
                    <Tooltip
                      title={
                        <Typography color="inherit">
                          Click this button to archive this user.
                        </Typography>
                      }
                      placement="right"
                      arrow
                    >
                      <Button
                        onClick={() => {
                          setPopupArchiveUser(true);
                        }}
                        disabled={disabled}
                        className={classes.disableButton}
                      >
                        Archive user
                      </Button>
                    </Tooltip>
                  )}
                </ListItem>
                <Backdrop className={classes.backdrop} open={loading}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              </List>
            </Container>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={tabNr} index={1}>
        {userDocument.parts.map((part, partIndex) => (
          <div className={classes.documentPart} key={partIndex}>
            <div className={classes.inputFieldRow}>
              <DropdownWithTooltip
                label={`Type of document`}
                value={userDocument.parts[partIndex].userDocumentType}
                values={documentTypes}
                onChange={(value) =>
                  setUserDocument({
                    ...userDocument,
                    parts: userDocument.parts.map((part, index) => {
                      if (index === partIndex) {
                        part.userDocumentType = value;
                      }
                      return part;
                    }),
                  })
                }
              />
            </div>
          </div>
        ))}
        {currentFile ? (
          <div style={{ position: "relative" }}>
            {currentFile.name
              .substr(currentFile.name.lastIndexOf(".") + 1)
              .toLowerCase() === "pdf" ? (
              <object
                className={
                  isMobileDevice
                    ? classes.previewUploadOnMobile
                    : classes.previewUploadOnDesktop
                }
                type="application/pdf"
                data={imageAsURL}
              >
                <div className={classes.pdfUnsupportedMessage}>
                  This browser does not support previewing PDF files.
                </div>
              </object>
            ) : (
              <div>
                <img
                  style={{ zIndex: -1 }}
                  className={
                    isMobileDevice
                      ? classes.previewUploadOnMobile
                      : classes.previewUploadOnDesktop
                  }
                  src={imageAsURL}
                  alt="did not load"
                />
              </div>
            )}
            <Button
              variant="contained"
              className={`${classes.trashButton} ${classes.moveUpToOverlap}`}
              onClick={() => {
                setCurrentFile(undefined);
                setUserDocument({ ...userDocument, base64EncodedFile: "" });
              }}
            >
              <Delete />
            </Button>
          </div>
        ) : (
          <div
            className={classes.uploadBlock}
            onClick={(event) => document.getElementById(uploadID)?.click()}
          >
            <Typography variant="subtitle2">
              <u>Click to upload document</u>
              <input
                type="file"
                id={uploadID}
                accept="image/*,.pdf"
                hidden
                onChange={(event) => {
                  let file: File | undefined | null =
                    event.target.files?.item(0);
                  if (file) {
                    var reader: FileReader = new FileReader();

                    reader.onload = function () {
                      const csv: string = reader.result as string;
                      setUserDocument({
                        ...userDocument,
                        parts: userDocument.parts.map((part, index) => {
                          if (index === 0) {
                            part.personId = userId;
                          }
                          return part;
                        }),
                        base64EncodedFile: csv,
                      });
                      setCurrentFile(file!);
                      if (
                        file!.type.startsWith("image") ||
                        file!.type.endsWith("pdf")
                      ) {
                        if (file) {
                          setImageAsURL(URL.createObjectURL(file));
                        }
                        /*      validator.validate(
                               "base64EncodedFile",
                               getValidationMessageForDocument("base64EncodedFile"),
                               getValidationFunctionForDocument("base64EncodedFile"),
                               csv
                             );
                             */
                      }
                    };

                    reader.readAsDataURL(file!);
                  } else {
                    showError("You did not upload a file");
                  }
                }}
              ></input>
            </Typography>
            <div>All image formats or PDF</div>
          </div>
        )}

        <Button
          disabled={submitting}
          className={classes.submitButton}
          onClick={uploadDocument}
          fullWidth
        >
          Upload
        </Button>
        <ListItem></ListItem>
        <ListItem>
          <Typography variant="h6">{`DOCUMENTS`} </Typography>
        </ListItem>
        {loadingDocumentMenu ? (
          <div className={classes.spinnerContainer}>
            <CircularProgress disableShrink className={classes.spinner} />
          </div>
        ) : (
          <DocumentTable
            aUser={aUser}
            aUsers={users}
            aDocuments={undeletedUserDocuments}
            aTableType={tableType}
            aListRef={listRef}
            aPreviousScrollDiff={previousScrollDiff}
          />
        )}
      </TabPanel>
    </div>
  );
};
