import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { Container, createStyles, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { AdminRoutes } from "./page/AdminRoutes";
import { EmployeeRoutes } from "./page/EmployeeRoutes";
import {
  actionAuthorizationEnd,
  actionLogout,
  actionRemoveAccount,
  actionRemoveToken,
  AuthenticationActions,
} from "./redux/actions";
import LoadingComponent from "./component/Authentication/Loading";
import WithAppBar from "./WithAppBar";
import { SnackbarProvider } from "./component/SnackbarProvider";
import { Account } from "./data/principal";
import { RootState } from "./redux/reducers";
import UserApi from "./data/user";
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles(() =>
  createStyles({
    appContainer: {
      marginBottom: "100px",
    },
  })
);

const Routes = (currentUser: Account | null) => {
  
  const [status, setStatus] = useState<string>("");
  const history = useHistory();

  useEffect(() => {
    UserApi.getUserStatus().then((res) => 
      setStatus(res)
    )
  },[])


  if (!currentUser) {
    return null;
  }

  if(status === "PENDINGPROFILECOMPLETION"){
    history.push("/employee/profile");
  }
  return (
    <div>
      <AdminRoutes {...currentUser} />
      <EmployeeRoutes {...currentUser} />
      <Route path="/" exact>
        <Redirect
          to={
            currentUser?.isEmployee
              ? currentUser?.addressId
                ? "/employee"
                : "/employee/profile"
              : "/admin"
          }
        />
      </Route>
    </div>
  );
};

export const AppAuthorize = () => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.auth.isLoggedIn
  );
  const account = useSelector<RootState, Account | null>(
    (state) => state.auth.currentAccount
  );
  const refreshToken = useSelector<RootState, string | null>(
    (state) => state.auth.refreshToken
  );

  const [showLoading, setShowLoading] = useState<boolean>(true);

  useEffect(() => {
    // If the state claims we are able to login that means we can safely redirect back to the webroot
    if (isLoggedIn && account !== null) {
      setShowLoading(false);
      return;
    }

    // Empty the state if only part is incorrect
    dispatch(actionAuthorizationEnd());
    dispatch(actionRemoveToken());
    dispatch(actionRemoveAccount());
    dispatch(actionLogout());

    if (refreshToken !== null) {
      // If we have a refresh token then attempt to refresh the authentication
      dispatch(AuthenticationActions.loginRefresh(refreshToken));
    } else {
      // If we don't have a refresh token we have to head to Google
      dispatch(
        AuthenticationActions.loginRedirect(
          window.location.origin + "/authCallback"
        )
      );
    }
  }, [account, isLoggedIn, refreshToken, dispatch]);

  if (showLoading) {
    return <LoadingComponent />;
  } else {
    return <Redirect to="/" />;
  }
};

export const AppBodyAuthCallback = () => {
  const dispatch = useDispatch();

  const isInAuthorization = useSelector<RootState, boolean>(
    (state) => state.auth.isInAuthorization
  );
  if (!isInAuthorization) {
    return <Redirect to="/authorize" />;
  }

  const params = new URLSearchParams(window.location.search);
  const oidcCode = params.get("code");
  const oidcState = params.get("state");
  if (oidcCode === null || oidcState === null) {
    return (
      <React.Fragment>
        <p>No authorization code present</p>
      </React.Fragment>
    );
  }

  dispatch(
    AuthenticationActions.loginAuthorization(
      oidcCode,
      oidcState,
      window.location.origin + "/authCallback"
    )
  );

  return <LoadingComponent />;
};

export default function App() {
  // TODO: manage alle alerts via Redux en gebruik deze callback om bestaande alerts weg te gooien on page change
  //const dispatch = useDispatch();
  //History.listen((location, action) => {
  // clear alert on location change
  //dispatch(AlertActions.clear());
  //});

  const classes = useStyles();

  // Note: Only import the necessary Redux values, too many of these will cause an infinite loop
  // of attempting to re-render the component
  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.auth.isLoggedIn
  );
  const currentAccount = useSelector<RootState, Account | null>(
    (state) => state.auth.currentAccount
  );

  if (isLoggedIn && currentAccount !== null) {
    return (
      <React.Fragment>
        <WithAppBar />
        <Container maxWidth={false} className={classes.appContainer}>
          <SnackbarProvider>
            <Routes {...currentAccount} />
          </SnackbarProvider>
        </Container>
      </React.Fragment>
    );
  } else {
    return <Redirect to="/authorize" />;
  }
}
