/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  createStyles,
  Grid,
  List,
  ListItem,
  makeStyles,
  Theme,
  Tooltip,
  TextField,
} from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";

import { TextInput } from "../component/TextInput";
import { NumberInput } from "../component/NumberInput";
import { Dropdown } from "../component/Dropdown";
import { DatePicker } from "../component/DatePicker";
import { Utils } from "../data/util";
import { nationalities } from "../data/nationalities";
import { SnackbarContext } from "../component/SnackbarProvider";
import {
  isNotEmpty,
  isValidDate,
  isValidIbanOrEmpty,
  isValidPhoneNumberOrEmpty,
  useValidator,
  isValidEmail,
} from "../hooks/validator";
import UserApi, {
  Gender,
  Role,
  TravelOption,
  UpdateUser,
  UserItem,
} from "../data/user";
import { Prediction } from "../data/googleMaps";
import { GoogleMapsPlacefinder } from "../component/GoogleMapsPlacefinder";
import { Alert } from "@material-ui/lab";
import { HttpError } from "../component/HttpError";
import { v4 as uuidv4 } from "uuid";
import { Account } from "../data/principal";
import { RemoteDataContainer } from "../component/RemoteDataContainer";
import { useSelector } from "react-redux";
import { RootState } from "../redux/reducers";
import { HolidayAllowancePerYear } from "../data/holiday-allowance";
import { getUnit, sumBalance } from "../utils/HolidayAllowance.util";
import UserDocumentApi, {
  CreateUserDocument,
  UserDocumentDataItem,
  UserDocumentType,
} from "../data/userDocument";
import GoogleWorkspaceApi from "../data/googleWorkspace";
import UploadDocuments from "../component/Documents/UploadDocuments";
interface RouterMatch {
  id: string;
}

const emptyUser: UpdateUser = {
  userId: "",
  firstName: "",
  initials: "",
  middleName: "",
  lastName: "",
  name: "",
  emailAddress: "",
  googleAccountActive: undefined,
  dateOfBirth: "",
  nationality: "",
  gender: Gender.NotSpecified,
  iban: "",
  licensePlateNumber: "",
  phoneNumber: "",
  privateEmailAddress: "",
  linkedinUrl: "",
  hardwarePreferences: "",
  plannedHolidays: "",
  employmentStartDate: "",
  employmentEndDate: "",
  travelOption: TravelOption.None,
  mobilityBudgetAmount: "0",
  contractedHours: 0,
  roles: [],
  projects: [],
  googleAddressId: "",
  googleAddress: "",
  googleSessionToken: "",
  employeeNumber: "",
  userAccountStatus: "",
  errorMessage: "",
};

const mandatoryUpdateEmployeeFields: (keyof UpdateUser)[] = [
  "firstName",
  "initials",
  "lastName",
  "dateOfBirth",
  "nationality",
  "iban",
  "privateEmailAddress",
];
const mandatoryUpdateAdminFields: (keyof UpdateUser)[] = [];
const getValidationMessage = (field: keyof UpdateUser): string => {
  switch (field) {
    case "firstName":
      return "First name must not be empty";
    case "initials":
      return "Initials must not be empty";
    case "lastName":
      return "Last name must not be empty";
    case "dateOfBirth":
      return "Date of birth is required";
    case "nationality":
      return "Nationality is required";
    case "googleAddressId":
      return "Home address is required";
    case "iban":
      return "IBAN must be valid or empty";
    case "phoneNumber":
      return "Phone number must be valid or empty";
    case "privateEmailAddress":
      return "Private email address must not be empty and must be valid";
  }
  return "";
};

const getValidationFunction = (
  field: keyof UpdateUser
): ((value: any) => boolean) => {
  switch (field) {
    case "firstName":
      return isNotEmpty;
    case "initials":
      return isNotEmpty;
    case "lastName":
      return isNotEmpty;
    case "dateOfBirth":
      return isValidDate;
    case "nationality":
      return isNotEmpty;
    case "iban":
      return isValidIbanOrEmpty;
    case "phoneNumber":
      return isValidPhoneNumberOrEmpty;
    case "privateEmailAddress":
      return isValidEmail;
  }
  return () => false;
};

const getRequiredFieldsForSettings = (roles: Role[]): (keyof UpdateUser)[] => {
  let requiredFields: (keyof UpdateUser)[];
  if (roles.includes(Role.Employee)) {
    requiredFields = mandatoryUpdateEmployeeFields;
  } else {
    requiredFields = mandatoryUpdateAdminFields;
  }
  return requiredFields;
};

const genderValues = {
  MALE: "Male",
  FEMALE: "Female",
  NOT_SPECIFIED: "Not specified",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    amountInput: {
      width: "40%",
      marginRight: "5px",
    },
    submitButton: {
      backgroundColor: "#000000",
      color: "#FFFFFF",
      marginTop: "20px",
      "&:disabled": {
        backgroundColor: "#FFFFFF",
      },
    },
    documentPart: {
      marginTop: "30px",
    },
    horizontalChildren: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
    indicator: {
      backgroundColor: "#0081d7",
    },
    inactiveTab: {
      "&:checked": { backgroundColor: "green" },
      "&:hover": {
        backgroundColor: "#cccccc",
        color: "black !important",
      },
    },
    inputFieldRow: {
      marginTop: "30px",
      marginBottom: "10px",
    },
    moveUpToOverlap: {
      position: "absolute",
      bottom: 15,
      right: 15,
      marginTop: "-35px",
    },
    pdfUnsupportedMessage: {
      height: "80px",
    },
    previewUploadOnDesktop: {
      minWidth: window.innerWidth * 0.475,
      maxWidth: window.innerWidth * 0.475,
      paddingBottom: "10px",
    },
    previewUploadOnMobile: {
      minWidth: window.innerWidth * 0.9,
      maxWidth: window.innerWidth * 0.9,
      paddingBottom: "10px",
    },
    spinner: {
      marginLeft: "50%",
    },
    spinnerContainer: {
      position: "relative",
    },
    tab: {
      color: "#0081d7",
    },
    trashButton: {
      float: "right",
      cursor: "pointer",
    },
    uploadBlock: {
      border: "2px dashed #bbb",
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      height: "45px",
      marginTop: "20px",
      paddingTop: "10px",
      paddingBottom: "55px",
      textAlign: "center",
      "&:hover": { cursor: "pointer" },
    },
    whiteToBlackButton: {
      float: "right",
      marginTop: "10px",
      "&:hover": {
        backgroundColor: "#000000",
        color: "#FFFFFF",
      },
    },

    disableButton: {
      marginLeft: "2em",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    classMuiGrid: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
  })
);

const HolidayAllowanceDetails = (
  allowances: HolidayAllowancePerYear | undefined
) => {
  const determineTitle = (allowances: HolidayAllowancePerYear): string => {
    const currentYear = new Date().getFullYear();
    const balancePreviousYear = sumBalance(
      Object.entries(allowances)
        .filter(([key, value]) => key !== currentYear.toFixed())
        .reduce(
          (acc, [key, value]) => ({ ...acc, [key]: value }),
          {} as HolidayAllowancePerYear
        )
    ).toFixed(1);
    const currentAllowance = allowances[currentYear];

    const potentialBalanceResult = currentAllowance.potentialEarnedDays + sumBalance(allowances);

    return (
      `Actual \n` +
      `Balance previous years: ${balancePreviousYear} ${getUnit(+balancePreviousYear)}\n` +
      `Allowance this year: ${currentAllowance.earned.toFixed(1)} ${getUnit(currentAllowance.earned)}\n` +
      `Spent this year: ${currentAllowance.used.toFixed(1)} ${getUnit(currentAllowance.used)}\n` +
      `Balance: ${sumBalance(allowances).toFixed(1)} ${getUnit(sumBalance(allowances))} \n` +
      `\n` +
      `Potential \n` +
      `Potentail days to be earned: ${currentAllowance.potentialEarnedDays.toFixed(1)} ${getUnit(currentAllowance.potentialEarnedDays)} \n` +
      `Potentail balance: ${potentialBalanceResult.toFixed(1)} ${getUnit(potentialBalanceResult)}`
    );
  };

  if (allowances) {
    return (
      <Tooltip
        title={
          <span style={{ whiteSpace: "pre-line" }}>
            {determineTitle(allowances)}
          </span>
        }
        placement="right"
        arrow
      >
        <ListItem>
          <TextInput
            id="remaining-holiday-allowance"
            label="Remaining holiday allowance"
            value={
              sumBalance(allowances).toFixed(1) +
              " " +
              getUnit(sumBalance(allowances))
            }
          />
        </ListItem>
      </Tooltip>
    );
  }
};

const UserAllowance = ({ userProp }: { userProp: UserItem }) => (
  <List>{HolidayAllowanceDetails(userProp.holidayAllowances)}</List>
);

export const ProfileItem = (props: RouteComponentProps<RouterMatch>) => {
  const listRef = useRef<HTMLDivElement>(null);
  const previousScrollDiff = useRef(0);
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop =
        listRef.current.scrollHeight - previousScrollDiff.current;
    }
  },[]);

  const account = useSelector<RootState>(
    (state) => state.auth.currentAccount
  ) as Account | null;
  const userId = account ? account.id : "new";

  const [user, setUser] = useState<UpdateUser>(emptyUser);
  const { showInfo, showError } = useContext(SnackbarContext);
  const [validationErrorVisible, setValidationErrorVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [showHttpError, setShowHttpError] = useState(false);
  const [retryAction, setRetryAction] = useState<() => void>();
  const classes = useStyles();
  const validator = useValidator<UpdateUser>(
    user,
    getRequiredFieldsForSettings([])
  );
  const [currentFile, setCurrentFile] = useState<File | undefined>(undefined);
  const [imageAsURL, setImageAsURL] = useState<string>("");

  const [documentTypes, setDocumentTypes] = useState<{
    [key: string]: string;
  }>({});
  const [userDocument, setUserDocument] = useState<CreateUserDocument>({
    parts: [
      {
        personId: "",
        userDocumentType: UserDocumentType.SIGNEDLOONHEFFINGSVERKLARING,
      },
    ],
    base64EncodedFile: "",
  });
  const [upload, setUpload] = useState<boolean>(false);
  const [loadingGoogleAddressNull, setLoadingGoogleAddressNull] =
    useState<boolean>(false);
  const [undeletedUserDocument, setUndeletedUserDocument] =
    useState<UserDocumentDataItem>();

  useEffect(() => {
    if (
      user.googleAddressId === undefined &&
      user.roles?.includes(Role.Employee)
    ) {
      setLoadingGoogleAddressNull(true);
    }
  }, [user]);

  const uploadDocument = (): void => {
    let action = () => {
      setShowHttpError(false);
      if (undeletedUserDocument !== undefined) {
        UserDocumentApi.deleteUserDocument(undeletedUserDocument.id)
          .then()
          .catch((err) => {
            showError(
              `Faild to delete the previous document with submission date:${undeletedUserDocument.submissionDate}, plear try again!`
            );
          });
      }

      UserDocumentApi.createUserDocument(userDocument).then(
        () => {
          setUpload(true);
          showInfo("Document was uploaded");
          setCurrentFile(undefined);
          setImageAsURL("");
          setUserDocument({
            parts: [
              {
                personId: "",
                userDocumentType: UserDocumentType.SIGNEDLOONHEFFINGSVERKLARING,
              },
            ],
            base64EncodedFile: "",
          });
        },
        (err) => {
          if (err.message !== "Network Error") {
            showError("Failed to upload document");
          } else {
            setShowHttpError(true);
          }
        }
      );
      setUpload(false);
    };
    setRetryAction(() => action);
    action();
  };

  const onChangeUserAddress = (value: Prediction | null) => {
    if (value != null) {
      setUser({
        ...user,
        googleAddressId: value.placeId,
        googleAddress: value.description,
      });
      validator.validate(
        "googleAddressId",
        getValidationMessage("googleAddressId"),
        getValidationFunction("googleAddressId"),
        value.placeId
      );
    }
  };

  const saveUser = () => {
    if (undeletedUserDocument === undefined) {
      if (upload === false) {
        uploadDocument();
      } else {
        showError(
          `Failed to update the profile! All required documents should be uploaded!`
        );
        return;
      }
    }
    setDisabled(true);
    validator.validateMultiple(
      getRequiredFieldsForSettings(user.roles ?? []).map((field) => {
        return {
          field,
          message: getValidationMessage(field),
          valid: getValidationFunction(field),
        };
      })
    );

    if (!validator.isValid) {
      setValidationErrorVisible(true);
      window.scrollTo(0, 0);
      setDisabled(false);
      return;
    }
    setValidationErrorVisible(false);

    let action = () => {
      setDisabled(true);
      setShowHttpError(false);
      let request = UserApi.updateUser({
        userId: user.userId,
        firstName: user.firstName,
        initials: user.initials,
        middleName: user.middleName,
        lastName: user.lastName,
        name: `${user.firstName} ${user.middleName || ""} ${user.lastName}`,
        emailAddress: user.emailAddress,
        googleAccountActive: user.googleAccountActive,
        dateOfBirth: user.dateOfBirth,
        nationality: user.nationality,
        gender: user.gender ?? Gender.NotSpecified,
        googleAddressId: undefined,
        googleAddress: user.googleAddress,
        googleSessionToken: user.googleSessionToken,
        iban: user.iban,
        licensePlateNumber: user.licensePlateNumber,
        phoneNumber: user.phoneNumber,
        privateEmailAddress: user.privateEmailAddress,
        linkedinUrl: user.linkedinUrl,
        hardwarePreferences: user.hardwarePreferences,
        plannedHolidays: user.plannedHolidays,
        employmentStartDate: user.employmentStartDate,
        employmentEndDate: user.employmentEndDate,
        travelOption: user.travelOption ?? TravelOption.None,
        mobilityBudgetAmount: user.mobilityBudgetAmount,
        contractedHours: user.contractedHours,
        roles: user.roles,
        projects: user.projects,
        userAccountStatus: user.userAccountStatus,
        errorMessage: user.errorMessage,
      });

      request.then(
        () => {
          showInfo(`${`Updated`} user`);
          setTimeout(() => setDisabled(false), 2000);
          GoogleWorkspaceApi.sendEmails(user.userId);
          if (loadingGoogleAddressNull) {
            window.location.reload();
          }
        },
        (err) => {
          if (err.message !== "Network Error") {
            showError(`Failed to ${`updated`} user: \n${err.response.data}`);
          } else {
            setShowHttpError(true);
          }
          setDisabled(false);
        }
      );
    };
    setRetryAction(() => action);
    action();

    if (upload === false) {
      setTimeout(function () {
        uploadDocument();
      }, 3000);
    }
  };

  useEffect(() => {
    if (loading) {
      UserApi.getUser(userId).then(
        (res) => {
          setUser({
            userId: res.userId,
            firstName: res.firstName,
            initials: res.initials,
            middleName: res.middleName,
            lastName: res.lastName,
            name: `${res.firstName} ${res.middleName || ""} ${res.lastName}`,
            emailAddress: res.emailAddress,
            googleAccountActive: res.googleAccountActive,
            googleAddressId: res.homeAddressId,
            googleAddress: res.homeAddress,
            googleSessionToken: uuidv4(),
            contractedHours: res.contractedHours,
            dateOfBirth: res.dateOfBirth,
            employmentEndDate: res.employmentEndDate,
            employmentStartDate: res.employmentStartDate,
            gender: res.gender,
            iban: res.iban,
            licensePlateNumber: res.licensePlateNumber,
            phoneNumber: res.phoneNumber,
            privateEmailAddress: res.privateEmailAddress,
            linkedinUrl: res.linkedinUrl,
            hardwarePreferences: res.hardwarePreferences,
            plannedHolidays: res.plannedHolidays,
            mobilityBudgetAmount: res.mobilityBudgetAmount,
            nationality: res.nationality,
            projects: res.projects,
            roles: res.roles,
            travelOption: res.travelOption,
            employeeNumber: res.employeeNumber,
            userAccountStatus: res.userAccountStatus,
            errorMessage: res.errorMessage,
          });
          validator.updateMandatoryInputs(
            getRequiredFieldsForSettings(user.roles ?? []),
            true
          );
          setLoading(false);
        },
        (err) => {
          showError("Failed to load user");
          setLoading(false);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (Object.entries(documentTypes).length === 0) {
      UserDocumentApi.getUserDocumentTypes().then((res) => {
        const values = Object.entries(res)
          .filter(
            ([key, value]) =>
              !(
                UserDocumentType.PASSPORTORIDCARD === key ||
                UserDocumentType.UNSIGNEDEMPLOYMENTCONTRACT === key ||
                UserDocumentType.UNSIGNEDLOONHEFFINGSVERKLARING === key ||
                UserDocumentType.SIGNEDEMPLOYMENTCONTRACT === key
              )
          )
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
        setDocumentTypes(values);
        setUserDocument({
          ...userDocument,
          parts: [
            {
              personId: "",
              userDocumentType: UserDocumentType.SIGNEDLOONHEFFINGSVERKLARING,
            },
          ],
        });
      });
    }
  }, [userDocument, documentTypes]);

  if (!account) {
    return null;
  }

  return (
    <div>
      <HttpError
        open={showHttpError}
        close={() => setShowHttpError(false)}
        retry={() => {
          retryAction && retryAction();
        }}
      />

      <Grid container>
        <Grid container item xs={9} className={classes.classMuiGrid}>
          <Container>
            <HttpError
              open={showHttpError}
              close={() => setShowHttpError(false)}
              retry={() => {
                retryAction && retryAction();
              }}
            />

            {validationErrorVisible && (
              <Alert
                severity="error"
                onClose={() => setValidationErrorVisible(false)}
              >
                Please correct the errors in your form and submit again.
              </Alert>
            )}
            <List>
              <ListItem>
                <TextInput
                  id="user-first-name"
                  label="First name"
                  value={user.firstName}
                  onChange={(firstName) => setUser({ ...user, firstName })}
                  error={validator.get("firstName")}
                  onBlur={() =>
                    validator.validate(
                      "firstName",
                      getValidationMessage("firstName"),
                      getValidationFunction("firstName")
                    )
                  }
                />
              </ListItem>
              <ListItem>
                <TextInput
                  id="user-initials"
                  label="Initials"
                  value={user.initials}
                  onChange={(initials) => setUser({ ...user, initials })}
                  error={validator.get("initials")}
                  onBlur={() =>
                    validator.validate(
                      "initials",
                      getValidationMessage("initials"),
                      getValidationFunction("initials")
                    )
                  }
                />
              </ListItem>
              <ListItem>
                <TextInput
                  id="user-middle-name"
                  label="Middle name (optional)"
                  value={user.middleName ?? ""}
                  onChange={(middleName) => setUser({ ...user, middleName })}
                />
              </ListItem>
              <ListItem>
                <TextInput
                  id="user-last-name"
                  label="Last name"
                  value={user.lastName}
                  onChange={(lastName) => setUser({ ...user, lastName })}
                  error={validator.get("lastName")}
                  onBlur={() =>
                    validator.validate(
                      "lastName",
                      getValidationMessage("lastName"),
                      getValidationFunction("lastName")
                    )
                  }
                />
              </ListItem>
              <ListItem>
                <TextInput
                  id="user-email-address"
                  label="first.lastname@quadsolutions.nl"
                  value={user.emailAddress}
                  onChange={undefined}
                  error={validator.get("emailAddress")}
                  onBlur={() =>
                    validator.validate(
                      "emailAddress",
                      getValidationMessage("emailAddress"),
                      getValidationFunction("emailAddress")
                    )
                  }
                />
              </ListItem>
              <ListItem id="user-date-of-birth">
                <DatePicker
                  label="Date of birth"
                  value={
                    user.dateOfBirth ? Utils.parseDate(user.dateOfBirth) : null
                  }
                  onChange={(date) => {
                    if (date instanceof Date && !isNaN(date?.getTime())) {
                      let dateOfBirth: string = Utils.formatDate(date);
                      setUser({ ...user, dateOfBirth });
                      validator.validate(
                        "dateOfBirth",
                        getValidationMessage("dateOfBirth"),
                        getValidationFunction("dateOfBirth"),
                        dateOfBirth
                      );
                    }
                  }}
                  error={validator.get("dateOfBirth")}
                  onBlur={(event) =>
                    validator.validate(
                      "dateOfBirth",
                      getValidationMessage("dateOfBirth"),
                      getValidationFunction("dateOfBirth")
                    )
                  }
                />
              </ListItem>
              <ListItem id="user-nationality">
                <Dropdown
                  label="Nationality"
                  value={user.nationality ?? ""}
                  values={nationalities}
                  onChange={(nationality) => {
                    setUser({ ...user, nationality });
                    validator.validate(
                      "nationality",
                      getValidationMessage("nationality"),
                      getValidationFunction("nationality"),
                      nationality
                    );
                  }}
                  error={validator.get("nationality")}
                  onClose={() =>
                    validator.validate(
                      "nationality",
                      getValidationMessage("nationality"),
                      getValidationFunction("nationality"),
                      user.nationality
                    )
                  }
                />
              </ListItem>
              <ListItem id="user-gender">
                <Dropdown
                  label="Gender"
                  value={user.gender || Gender.NotSpecified}
                  values={genderValues}
                  onChange={(gender) => {
                    setUser({ ...user, gender: gender as Gender });
                  }}
                />
              </ListItem>
              <ListItem id="user-address">
                <TextInput
                  id="user-address"
                  label="Address"
                  value={user.googleAddress ?? ""}
                  onChange={(googleAddress) =>
                    setUser({ ...user, googleAddress, googleAddressId: undefined })
                  } />
              </ListItem>
              <ListItem>
                <TextInput
                  id="user-iban"
                  label="IBAN"
                  value={user.iban ?? ""}
                  onChange={(iban) => {
                    setUser({ ...user, iban });
                  }}
                  error={validator.get("iban")}
                  onBlur={(event) => {
                    validator.validate(
                      "iban",
                      getValidationMessage("iban"),
                      getValidationFunction("iban")
                    );
                  }}
                />
              </ListItem>
              <ListItem>
                <TextInput
                  id="user-license-plate-number"
                  label="License plate number (optional)"
                  value={user.licensePlateNumber ?? ""}
                  onChange={(licensePlateNumber) =>
                    setUser({ ...user, licensePlateNumber })
                  }
                />
              </ListItem>
              <ListItem>
                <TextInput
                  id="user-phone-number"
                  label="Phone number (optional)"
                  value={user.phoneNumber ?? ""}
                  onChange={(phoneNumber) => setUser({ ...user, phoneNumber })}
                  error={validator.get("phoneNumber")}
                  onBlur={(event) =>
                    validator.validate(
                      "phoneNumber",
                      getValidationMessage("phoneNumber"),
                      getValidationFunction("phoneNumber")
                    )
                  }
                />
              </ListItem>
              <ListItem>
                <TextInput
                  id="private-email-address"
                  label="Private Email Address"
                  value={user.privateEmailAddress ?? ""}
                  onChange={(privateEmailAddress) =>
                    setUser({ ...user, privateEmailAddress })
                  }
                  error={validator.get("privateEmailAddress")}
                  onBlur={(event) =>
                    validator.validate(
                      "privateEmailAddress",
                      getValidationMessage("privateEmailAddress"),
                      getValidationFunction("privateEmailAddress")
                    )
                  }
                />
              </ListItem>
              <ListItem>
                <TextInput
                  id="linkedin-url"
                  label="LinkedIn Url (optional)"
                  value={user.linkedinUrl ?? ""}
                  onChange={(linkedinUrl) => setUser({ ...user, linkedinUrl })}
                />
              </ListItem>
              <ListItem>
                <TextField
                  id="hardware-preferences"
                  label="Hardware Preferences (optional)"
                  value={user.hardwarePreferences ?? ""}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setUser({
                      ...user,
                      hardwarePreferences: event.target.value,
                    })
                  }
                  multiline
                  rows={4}
                  placeholder="If you have no preference we'll get you a 32GB DELL/HP equivalent laptop plus standard keyboard, mouse, camera and headset. Also specify a preferred date to pick up the hardware at our office."
                  fullWidth={true}
                  margin="dense"
                  variant="outlined"
                />
              </ListItem>
              <ListItem>
                <TextField
                  id="planned-holidays"
                  label="Planned Holidays (optional)"
                  value={user.plannedHolidays ?? ""}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setUser({ ...user, plannedHolidays: event.target.value })
                  }
                  multiline
                  rows={4}
                  placeholder="Specify the dates of the holiday(s) you already planned."
                  fullWidth={true}
                  margin="dense"
                  variant="outlined"
                />
              </ListItem>
              <ListItem id="user-contracted-hours">
                <NumberInput
                  label="Contracted hours"
                  value={user.contractedHours || 0}
                  error={validator.get("contractedHours")}
                  onBlur={() =>
                    validator.validate(
                      "contractedHours",
                      getValidationMessage("contractedHours"),
                      getValidationFunction("contractedHours")
                    )
                  }
                />
              </ListItem>
              <ListItem>
                <UploadDocuments
                  userId={userId}
                  setCurrentFile={setCurrentFile}
                  setImageAsURL={setImageAsURL}
                  currentFile={currentFile}
                  imageAsURL={imageAsURL}
                  setUserDocument={setUserDocument}
                  userDocument={userDocument}
                  uploadDocument={uploadDocument}
                  upload={upload}
                  setUndeletedUserDocument={setUndeletedUserDocument}
                  undeletedUserDocument={undeletedUserDocument}
                  setUpload={setUpload}
                />
              </ListItem>
              {user.userId && (
                <ListItem>
                  <TextInput
                    id="employee-number"
                    label="Employee number"
                    value={user.employeeNumber ?? ""}
                  />
                </ListItem>
              )}

              <RemoteDataContainer
                fetch={() => UserApi.getUser(account.id)}
                errorMessage={() => "Failed loading user"}
                renderData={(userProp: UserItem) => (
                  <UserAllowance userProp={userProp} />
                )}
              />

              <ListItem>
                <Button
                  id="user-save-button"
                  variant="contained"
                  color="primary"
                  onClick={saveUser}
                  disabled={disabled}
                >
                  Save
                </Button>
              </ListItem>

              <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </List>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
};
